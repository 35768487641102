import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchTable from "../../Search/SearchTable";
import { getInvoicesKeyValue } from "./InvoicesTableHelpers";
import SearchPaging from "../../Search/SearchPaging";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Checkbox,
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import "./invoice.scss";

const InvoicesSearchResults = ({
  invoiceResults,
  handleSortOnClick,
  resultsPerPage,
  resultsTotal,
  handleChange,
  handleResultsPerPageOnChange,
  keyInfoLookup,
  globalFilters,
  userSettings,
  setGlobalFilters,
  filterChecked,
  setFilterChecked,
  onViewAsPDF,
  onDelete,
}) => {
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [statusFilterAnchorEl, setStatusFilterAnchorEl] = useState(null);
  const [frequencyFilterAnchorEl, setFrequencyFilterAnchorEl] = useState(null);
  const actionOpen = Boolean(actionAnchorEl);
  const statusFilterOpen = Boolean(statusFilterAnchorEl);
  const frequencyFilterOpen = Boolean(frequencyFilterAnchorEl);
  const history = useHistory();

  const handleFilterOnClick = (e, column) => {
    if (column === "status") setStatusFilterAnchorEl(e.currentTarget);
    else if (column === "frequency")
      setFrequencyFilterAnchorEl(e.currentTarget);
  };

  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handleClick = (e, invoice) => {
    setSelectedInvoice(invoice);
    setActionAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setActionAnchorEl(null);
  };

  const currentFieldList = keyInfoLookup.filter(
    (row) => row.default_field === true,
  );

  const getActionsComponent = (row) => {
    return (
      <IconButton aria-label="options" onClick={(e) => handleClick(e, row)}>
        <MoreVertIcon />
      </IconButton>
    );
  };

  const handleFilter = (column) => {
    if (filterChecked) {
      setGlobalFilters({
        ...globalFilters,
        [column]: { operator: "Equal", value: filterChecked.value },
      });
    } else {
      setGlobalFilters({ ...globalFilters, [column]: undefined });
    }
    setStatusFilterAnchorEl(null);
    setFrequencyFilterAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(selectedInvoice);
    handleClose();
  };

  const filterStatusList = {
    Draft: 1,
    // Scheduled: 2,
    Sent: 3,
    Paid: 4,
    "Partially Paid": 5,
    // "Payment Pending": 6,
    Overdue: 7,
    Cancelled: 8,
  };

  const filterFrequencyList = {
    "One Time": 1,
    Recurring: 2,
  };

  return (
    <>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        className="action-menu"
        anchorEl={actionAnchorEl}
        open={actionOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {selectedInvoice?.invoiceStatus.name !== "Draft" &&
          selectedInvoice?.invoiceStatus.name !== "Cancelled" && (
            <MenuItem onClick={() => onViewAsPDF(selectedInvoice.invoiceId)}>
              <PictureAsPdfIcon color="secondary" />
              View invoice as PDF
            </MenuItem>
          )}
        {(selectedInvoice?.createdByUserId === userSettings.user.userId ||
          userSettings.role.code === "SU" ||
          userSettings.role.code === "SA") &&
          (selectedInvoice.invoiceStatus.invoiceStatusId < 4 ||
            selectedInvoice.invoiceStatus.invoiceStatusId === 7) && (
            <MenuItem
              onClick={() =>
                history.push(
                  `${merchantRoutes.invoices.base}/${selectedInvoice.invoiceId}`,
                  { edit: true },
                )
              }
            >
              <CreateOutlinedIcon color="secondary" />
              Edit invoice
            </MenuItem>
          )}
        {userSettings?.gatewayUserPermissions["CREATE_INVOICE"] && (
          <MenuItem
            onClick={() =>
              history.push(merchantRoutes.invoices.create, {
                invoiceId: selectedInvoice.invoiceId,
              })
            }
          >
            <ContentCopyIcon color="secondary" />
            Clone invoice
          </MenuItem>
        )}
        {selectedInvoice?.invoiceStatus.name !== "Paid" &&
          selectedInvoice?.invoiceStatus.name !== "Partially Paid" &&
          selectedInvoice?.invoiceStatus.name !== "Cancelled" &&
          userSettings?.gatewayUserPermissions["DELETE_INVOICE"] && (
            <MenuItem onClick={handleDelete}>
              {selectedInvoice?.invoiceStatus.name === "Draft" ? (
                <>
                  <CancelOutlinedIcon color="secondary" />
                  Delete invoice
                </>
              ) : (
                <>
                  <DoNotDisturbOnOutlinedIcon color="secondary" />
                  Cancel invoice
                </>
              )}
            </MenuItem>
          )}
        {selectedInvoice?.transactionIds?.length > 0 && (
          <MenuItem
            onClick={() =>
              history.push(merchantRoutes.reporting.transactions, {
                invoiceId: selectedInvoice?.invoiceId,
              })
            }
          >
            <ReceiptIcon color="secondary" />
            View{" "}
            {selectedInvoice?.transactionIds.length > 1
              ? "transactions"
              : "transaction"}
          </MenuItem>
        )}
        {selectedInvoice?.customer && (
          <MenuItem
            onClick={() =>
              history.push(
                `${merchantRoutes.customer.base}/${selectedInvoice.customer.customerId}`,
              )
            }
          >
            <AccountCircleIcon color="secondary" />
            View customer
          </MenuItem>
        )}
      </Menu>

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={statusFilterAnchorEl}
        open={statusFilterOpen}
        onClose={() => handleFilter("status")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="filter-status-menu"
      >
        {Object.keys(filterStatusList).map((status, i) => (
          <MenuItem key={i}>
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  color="secondary"
                  value={filterStatusList[status]}
                  checked={
                    filterChecked?.column === "status" &&
                    filterChecked?.value === filterStatusList[status]
                  }
                  onClick={(e) => {
                    setFilterChecked({
                      column: "status",
                      value: parseInt(e.target.value),
                    });
                  }}
                />
              }
              label={status}
            />
          </MenuItem>
        ))}
        <Grid container justifyContent="space-evenly">
          <Button
            className="reset-filter-button"
            onClick={() => {
              setFilterChecked(null);
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleFilter("status")}
          >
            Filter
          </Button>
        </Grid>
      </Menu>

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={frequencyFilterAnchorEl}
        open={frequencyFilterOpen}
        onClose={() => handleFilter("type")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="filter-status-menu"
      >
        {Object.keys(filterFrequencyList).map((frequency, i) => (
          <MenuItem key={i}>
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  color="secondary"
                  value={filterFrequencyList[frequency]}
                  checked={
                    filterChecked?.column === "type" &&
                    filterChecked?.value === filterFrequencyList[frequency]
                  }
                  onClick={(e) => {
                    setFilterChecked({
                      column: "type",
                      value: parseInt(e.target.value),
                    });
                  }}
                />
              }
              label={frequency}
            />
          </MenuItem>
        ))}
        <Grid container justifyContent="space-evenly">
          <Button
            className="reset-filter-button"
            onClick={() => {
              setFilterChecked(null);
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleFilter("type")}
          >
            Filter
          </Button>
        </Grid>
      </Menu>

      <SearchTable
        getEntityKeyValue={(key, data) =>
          getInvoicesKeyValue(key, data, userSettings)
        }
        fieldList={currentFieldList}
        searchResults={invoiceResults}
        getActionsComponent={getActionsComponent}
        handleSortOnClick={handleSortOnClick}
        handleFilterOnClick={handleFilterOnClick}
      />
      <SearchPaging
        resultsPerPage={resultsPerPage}
        resultsTotal={resultsTotal}
        handleChange={handleChange}
        handleResultsPerPageOnChange={handleResultsPerPageOnChange}
      />
    </>
  );
};

InvoicesSearchResults.propTypes = {
  invoiceResults: PropTypes.array,
  resultsPerPage: PropTypes.number,
  resultsTotal: PropTypes.number,
  handleChange: PropTypes.func,
  handleResultsPerPageOnChange: PropTypes.func,
  fieldBoldness: PropTypes.string,
  userSettings: PropTypes.object,
  handleSortOnClick: PropTypes.func,
  handleChangeAlertDialogProps: PropTypes.func,
  showSnackBar: PropTypes.func,
  showAlertDialogError: PropTypes.func,
  keyInfoLookup: PropTypes.any,
  globalFilters: PropTypes.object,
  setGlobalFilters: PropTypes.func,
  filterChecked: PropTypes.object,
  setFilterChecked: PropTypes.func,
  onViewAsPDF: PropTypes.func,
  onDelete: PropTypes.func,
};

export default InvoicesSearchResults;
