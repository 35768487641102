import { useEffect, useState, useLayoutEffect, useContext } from "react";
import ContentComponent from "../../../Content/Content";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { sassEndpoints } from "../../../../constants/endpoints";
import Button from "@mui/material/Button";
import "./cvCustomerDetail.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { merchantRoutes } from "../../../../constants/routes";
import { useHistory, useLocation } from "react-router-dom";
import CustomerDetails from "./CVCustomerDetails";
import EditIcon from "@mui/icons-material/Edit";
import StyledTabs from "../../../Tabs/StyledTabs";
import CustomerPayment from "./CVCustomerPayment";
import CustomerAddress from "./CVCustomerAddress";
import CustomerTransactions from "./CVCustomerTransactions";
import CustomerSubscriptions from "./CVCustomerSubscriptions";
import PaymentIcon from "@mui/icons-material/Payment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import useAlertDialog from "../../../../hooks/useAlertDialog";
import AlertDialog from "../../../Dialog/AlertDialog";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { stringFormat } from "../../../../utils/stringHelpers";
import { GatewayContext } from "../../../../contexts/GatewayContext";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import LinkIcon from "@mui/icons-material/Link";
import { RecurringIcon } from "../../../Drawer/DrawerIcons/GatewayIcons";
import CVCustomerPayables from "./CVCustomerPayables";
import emptyImg from "../../../../assets/images/empty-state.png";
import { SUBSCRIPTION_STATUSES } from "../../../../constants/global";

const ViewCustomerContainer = () => {
  const { gatewayConfigSettings } = useContext(GatewayConfigContext);
  const gatewayInfo = useContext(GatewayContext);

  const flattenedRequiredDefaultFields = gatewayConfigSettings.categories
    .find((p) => p.code === "required_fields")
    .groups[0].fields.reduce((fields, f) => {
      if (typeof f.value === "object") {
        fields[f.code] = f.value[0].toLowerCase();
      } else {
        fields[f.code] = f.value.toLowerCase();
      }
      return fields;
    }, {});
  const { userSettings } = useContext(UserSettingsContext);
  const { id } = useParams();
  const { ...methods } = useForm();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [initialTab, setInitialTab] = useState(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const { isAlertOpen, setIsAlertOpen, alertMessages, displayMessages } =
    useAlertDialog();
  const locationPath = {
    0: "payments",
    1: "address",
    2: "transactions",
  };
  const tokenizerApi = userSettings.publicKey;
  const [customFieldGroups, setCustomFieldGroups] = useState(null);
  const [chosenCustomFields, setChosenCustomFields] = useState(null);
  const [newCustomerDetails, setNewCustomerDetails] = useState();
  const [achToken, setAchToken] = useState(null);
  const [generatedToken, setGeneratedToken] = useState(null);
  const gatewaySettings = gatewayInfo.gatewaySettings.reduce((settings, s) => {
    settings[s.code] = s.value.toLowerCase() === "true";
    return settings;
  }, {});
  const hasActiveSubscriptions = newCustomerDetails?.subscriptions?.some(
    (s) =>
      s.subscriptionStatus.name !== SUBSCRIPTION_STATUSES.CANCELLED ||
      s.subscriptionStatus.name !== SUBSCRIPTION_STATUSES.COMPLETE ||
      s.subscriptionStatus.name !== SUBSCRIPTION_STATUSES.DELETED,
  );

  useEffect(() => {
    const param = location.search;
    if (param) {
      setInitialTab(
        Object.keys(locationPath).find(
          (key) => locationPath[key] === param.split("?")[1],
        ),
      );
    } else {
      history.push({ search: locationPath[0] });
      setInitialTab(0);
    }
    setIsEdit(location?.state === "edit");
    loadCustomerData();
  }, []);

  useEffect(async () => {
    let url = stringFormat(sassEndpoints.customFields.category, [
      userSettings.gatewayId,
    ]);
    setShowBackdrop(true);
    axios
      .get(url)
      .then(function (res) {
        if (res.status !== 204) {
          let customerCustomFields = res.data?.data?.filter(
            (obj) => obj.customer === true,
          );
          let groupNames = customerCustomFields.map((group) => group.name);
          let customFields = customerCustomFields.map(
            (fields) => fields.customFields,
          );
          setCustomFieldGroups(groupNames);
          setChosenCustomFields(customFields);
        }
      })
      .catch(function () {
        showAlertDialogError("Failed to get custom fields");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  }, []);

  useLayoutEffect(
    () => () => {
      history.push({ search: null });
    },
    [],
  );

  const loadCustomerData = async () => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.customers.customerId, [
      userSettings.gatewayId,
      id,
    ]);
    axios
      .get(url)
      .then(function (res) {
        if (res.status === 204) {
          setNewCustomerDetails(null);
        } else {
          setNewCustomerDetails(res?.data?.data);
          if (res?.data?.data?.customerPayableToken?.length > 0) {
            setGeneratedToken(
              `${process.env.REACT_APP_IQPRO_PAY_URL}/invoice/payable/${res?.data?.data.customerPayableToken}`,
            );
          }
        }
      })
      .catch(function () {
        setNewCustomerDetails(null);
        showAlertDialogError("Failed to get customer details");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const handleVirtualTerminalNavigation = () => {
    history.push({
      pathname: `${merchantRoutes.virtual_terminal}/token/${id}`,
    });
  };
  const handleChange = (event) => {
    history.push({ search: locationPath[event] });
  };

  const handleCustomField = () => {
    let customFieldArray = [];
    let chosenGroup = methods.getValues().customer_field_group_select;

    methods.getValues().custom_fields[chosenGroup].forEach((fieldValue) => {
      if (fieldValue !== null && fieldValue.length > 0) {
        customFieldArray.push({
          customFieldId: methods
            .getValues()
            .custom_fields[chosenGroup].indexOf(fieldValue),
          customFieldValue:
            //field value here is required to be type [string]
            typeof fieldValue === "object" ? fieldValue : [fieldValue],
        });
      }
    });
    return customFieldArray;
  };

  const transformPayload = () => {
    let payload = {
      name: methods.getValues().name,
      description: methods.getValues().description,
    };
    if (methods.getValues().customer_field_group_select) {
      payload.customFields = handleCustomField();
    }
    return payload;
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  const editUser = async () => {
    let url = stringFormat(sassEndpoints.customers.customerId, [
      userSettings.gatewayId,
      id,
    ]);
    const payload = transformPayload();
    setShowBackdrop(true);
    axios
      .put(url, payload)
      .then(function () {
        setIsEdit(false);
        showSnackBar("Success");
        loadCustomerData();
      })
      .catch(function (error) {
        showAlertDialogError(
          error?.response?.data?.statusDetails || "Failed to update customer.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const backToList = () => {
    history.push({
      pathname: `${merchantRoutes.customer.search}`,
    });
  };

  const deleteCustomerData = async () => {
    let url = stringFormat(sassEndpoints.customers.customerId, [
      userSettings.gatewayId,
      id,
    ]);
    setShowBackdrop(true);
    axios
      .delete(url)
      .then(function () {
        showSnackBar("Deleted Customer");
        backToList();
      })
      .catch(function () {
        showAlertDialogError("Failed to delete customer");
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const onError = () => {
    showAlertDialogError("Please correct form.");
  };

  const cancelClose = () => {
    setIsAlertOpen(false);
  };

  // ########################################
  // # Subscriptions CRUD Operations
  // ########################################
  const pauseSubscription = (subscriptionId) => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.subscriptions.pause, [
      userSettings.gatewayId,
      subscriptionId,
    ]);

    axios
      .put(url)
      .then((resp) => {
        if (resp.status === 200) {
          showSnackBar("Subscription paused");
          loadCustomerData();
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to pause subscription.",
        );
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const resumeSubscription = (payload, subscriptionId) => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.subscriptions.resume, [
      userSettings.gatewayId,
      subscriptionId,
    ]);

    axios
      .put(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          showSnackBar("Subscription resumed");
          loadCustomerData();
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to resume subscription.",
        );
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const cancelSubscription = (payload, subscriptionId) => {
    setShowBackdrop(true);

    let url = stringFormat(sassEndpoints.subscriptions.cancel, [
      userSettings.gatewayId,
      subscriptionId,
    ]);

    axios
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 204) {
          showSnackBar(
            payload.cancel.now
              ? "Successfully cancelled Subscription"
              : "Successfully scheduled cancellation",
          );
          loadCustomerData();
        } else {
          showAlertDialogError(
            // eslint-disable-next-line prettier/prettier
            resp?.data?.data?.statusDetails?.join(", ") ||
              "Successfully cancelled subscription, but there were other errors",
          );
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to cancel subscription.",
        );
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  // ########################################
  // # Payment CRUD Operations
  // ########################################
  const createPayment = (token, isDefault, activeTab) => {
    setShowBackdrop(true);
    let payload = {
      [activeTab.activeTab]: {
        token: token?.token || token,
        ...(token?.expirationDate && {
          expirationDate: token.expirationDate,
          maskedCard: token.maskedCard,
        }),
      },
      ...(isDefault && { isDefault: true }),
    };
    let url = `${stringFormat(sassEndpoints.customers.customerId, [
      userSettings.gatewayId,
      id,
    ])}/payment`;
    axios
      .post(url, payload)
      .then(function () {
        showSnackBar("Success");
        loadCustomerData();
        setAchToken(null);
      })
      .catch(function (error) {
        setAchToken(null);
        showAlertDialogError(
          error?.response?.data?.statusDetails ||
            "Failed to create payment method.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const warningDeletePayment = (row) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: ["You are permanently deleting a customer payment!"],
      actionButtons: [
        {
          text: "Yes, Delete This Customer Payment!",
          color: "secondary",
          onclick: () => deletePayment(row),
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };
  const deletePayment = (rowId) => {
    let url = `${stringFormat(sassEndpoints.customers.payment, [
      userSettings.gatewayId,
      id,
      rowId,
    ])}`;
    setShowBackdrop(true);
    axios
      .delete(url)
      .then(function () {
        showSnackBar("Success");
        setAlertDialogOpen(false);
        loadCustomerData();
      })
      .catch(function (error) {
        // console.log(error);
        showAlertDialogError(
          error?.response?.data?.statusDetails || "Failed to delete payment.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const editPayment = (paymentId, token, activeTab, newDefaultType) => {
    const payload = {
      [activeTab.activeTab]: {
        token: token?.token || token,
        ...(token?.expirationDate && {
          expirationDate: token.expirationDate,
          maskedCard: token.maskedCard,
        }),
      },
      ...(newDefaultType && { isDefault: true }),
    };

    let url = `${stringFormat(sassEndpoints.customers.customerId, [
      userSettings.gatewayId,
      id,
    ])}/payment`;
    axios
      .post(url, payload)
      .then(function () {
        showSnackBar("Success");
        deletePayment(paymentId);
        setAchToken(null);
      })
      .catch(function (error) {
        setAchToken(null);
        showAlertDialogError(
          error?.response?.data?.statusDetails ||
            "Failed to create payment method.",
        );
        setShowBackdrop(false);
      });
  };

  const setDefaultPayment = (paymentId) => {
    let url = `${stringFormat(sassEndpoints.customers.payment, [
      userSettings.gatewayId,
      id,
      paymentId,
    ])}`;
    setShowBackdrop(true);
    axios
      .patch(url, [
        {
          path: "/isDefault",
          op: "replace",
          value: true,
        },
      ])
      .then(function () {
        showSnackBar("Success");
        loadCustomerData();
      })
      .catch(function (error) {
        showAlertDialogError(
          error?.response?.data?.statusDetails ||
            "Failed to set new payment default.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  // ########################################
  // # Address CRUD Operations
  // ########################################
  const createAddress = (addressForm) => {
    setShowBackdrop(true);
    let url = `${stringFormat(sassEndpoints.customers.customerId, [
      userSettings.gatewayId,
      id,
    ])}/address`;
    axios
      .post(url, addressForm)
      .then(function () {
        showSnackBar("Success");
        loadCustomerData();
      })
      .catch(function (error) {
        // console.log(error);
        showAlertDialogError(
          error?.response?.data?.statusDetails ||
            "Failed to create new customer address.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const editAddress = (selectedAddress, payload) => {
    let url = `${stringFormat(sassEndpoints.customers.address, [
      userSettings.gatewayId,
      id,
      selectedAddress.customerAddressId,
    ])}`;
    setShowBackdrop(true);
    axios
      .patch(url, payload)
      .then(function () {
        showSnackBar("Success");
        loadCustomerData();
      })
      .catch(function (error) {
        showAlertDialogError(error?.response?.data?.statusDetails);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const setDefaultAddress = (addressId, types) => {
    let payload = [
      {
        path: "/" + types,
        op: "replace",
        value: true,
      },
    ];
    let url = stringFormat(sassEndpoints.customers.address, [
      userSettings.gatewayId,
      id,
      addressId,
    ]);

    setShowBackdrop(true);
    axios
      .patch(url, payload)
      .then(function () {
        showSnackBar("Your new default address has been saved.");
        loadCustomerData();
      })
      .catch(function (error) {
        showAlertDialogError(
          error?.response?.data?.statusDetails ||
            "Failed to update default address.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const warningDeleteAddress = (row) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: ["You are permanently deleting a customer address!"],
      actionButtons: [
        {
          text: "Yes, Delete This Customer Address!",
          color: "secondary",
          onclick: () => deleteAddress(row),
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };
  const deleteAddress = (row) => {
    let url = stringFormat(sassEndpoints.customers.address, [
      userSettings.gatewayId,
      id,
      row.customerAddressId,
    ]);
    setShowBackdrop(true);
    axios
      .delete(url)
      .then(function () {
        showSnackBar("Success");
        setAlertDialogOpen(false);
        loadCustomerData();
      })
      .catch(function (error) {
        console.log(error);
        showAlertDialogError(
          error?.response?.data?.statusDetails || "Failed to delete address.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const createUrl = () => {
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.customers.payableURL, [
      userSettings.gatewayId,
      id,
    ]);
    axios
      .post(url)
      .then((response) => {
        setGeneratedToken(
          `${process.env.REACT_APP_IQPRO_PAY_URL}/invoice/payable/${response.data.data.token}`,
        );
      })
      .catch((error) => {
        showAlertDialogError(
          error?.response?.data?.statusDetails ||
            "Failed to generate customer payables URL.",
        );
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const copyLink = () => {
    showSnackBar("Copied URL to clipboard!");
    navigator.clipboard.writeText(generatedToken);
  };
  return (
    <FormProvider {...methods}>
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title={!isEdit ? "View Customer" : "Edit Customer"}
        headerContent={
          <div className="customer-detail-header">
            <Link to={merchantRoutes.customer.search} className="back-arrow">
              <ChevronLeftIcon />
              Back to List
            </Link>
            {newCustomerDetails !== null && (
              <div>
                {userSettings?.gatewayUserPermissions["VAULT_DELETE"] && (
                  <Tooltip
                    title={
                      hasActiveSubscriptions
                        ? "This customer cannot be deleted because they have active or pending subscriptions."
                        : ""
                    }
                  >
                    <span>
                      <Button
                        className="action-button"
                        size="small"
                        variant="contained"
                        disabled={hasActiveSubscriptions}
                        color="error"
                        onClick={() => {
                          displayMessages([
                            "You are permanently deleting customer!",
                          ]);
                        }}
                        data-cy="customer-delete-button"
                      >
                        Delete
                      </Button>
                    </span>
                  </Tooltip>
                )}
                <Button
                  className={
                    gatewaySettings["ALLOW_INVOICING"] &&
                    userSettings.gatewayUserPermissions["CREATE_INVOICE"] &&
                    "action-button"
                  }
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleVirtualTerminalNavigation();
                  }}
                  data-cy="customer-charge-button"
                >
                  Charge
                </Button>
                {gatewaySettings["ALLOW_INVOICING"] &&
                  userSettings.gatewayUserPermissions["CREATE_INVOICE"] && (
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        history.push({
                          pathname: merchantRoutes.invoices.create,
                          state: {
                            customerId: newCustomerDetails.customerId,
                          },
                        });
                      }}
                      data-cy="customer-invoice-button"
                    >
                      Create Invoice
                    </Button>
                  )}
              </div>
            )}
          </div>
        }
        bodyContent={
          <div className="customer-detail-container">
            {newCustomerDetails !== null ? (
              <>
                <h2>
                  My Customer Details
                  {userSettings?.gatewayUserPermissions["VAULT_UPDATE"] && (
                    <IconButton
                      title={"Edit"}
                      variant="container"
                      sx={{ padding: 0.5 }}
                      onClick={() => {
                        setIsEdit(!isEdit);
                      }}
                      color={"warning"}
                      fontSize="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                </h2>

                {newCustomerDetails && (
                  <div>
                    <CustomerDetails
                      isEdit={isEdit}
                      userSettings={userSettings}
                      onSubmit={editUser}
                      customerDetails={newCustomerDetails}
                      isSurcharge={
                        gatewayInfo.gatewaySettings
                          .find((p) => p.code === "ALLOW_SURCHARGE")
                          ?.value?.toLowerCase() === "true" || false
                      }
                      onError={onError}
                      customFieldGroups={customFieldGroups}
                      chosenCustomFields={chosenCustomFields}
                    />
                    <AlertDialog
                      alertTitle="Are you sure?"
                      alertMessages={alertMessages}
                      alertLevel="info"
                      alertOpen={isAlertOpen}
                      actionButtons={[
                        {
                          text: "Yes, Delete This Customer!",
                          color: "secondary",
                          onclick: () => deleteCustomerData(),
                        },
                      ]}
                      closeButtonColor="neutrals"
                      closeButtonText="Cancel"
                      onCloseButtonClick={() => cancelClose()}
                    />

                    <StyledTabs
                      hidePanels={true}
                      label="Customer View"
                      defaultTabIndex={+initialTab}
                      onChange={handleChange}
                      tabs={[
                        {
                          props: { label: "Payments", icon: <PaymentIcon /> },
                          panel: (
                            <div>
                              <CustomerPayment
                                customerDetails={newCustomerDetails}
                                requiredDefaultFields={
                                  flattenedRequiredDefaultFields
                                }
                                tokenizerKey={tokenizerApi}
                                createPayment={createPayment}
                                deletePayment={(row) =>
                                  warningDeletePayment(row)
                                }
                                editPayment={editPayment}
                                defaultPayment={setDefaultPayment}
                                permissions={
                                  userSettings.gatewayUserPermissions
                                }
                                showAlertDialogError={showAlertDialogError}
                                achToken={achToken}
                                setAchToken={setAchToken}
                              />
                            </div>
                          ),
                        },
                        {
                          props: { label: "Address", icon: <LocationOnIcon /> },
                          panel: (
                            <div>
                              <CustomerAddress
                                addresses={newCustomerDetails.addresses}
                                requiredDefaultFields={
                                  flattenedRequiredDefaultFields
                                }
                                createAddress={createAddress}
                                deleteAddress={(row) =>
                                  warningDeleteAddress(row)
                                }
                                editAddress={editAddress}
                                defaultAddress={setDefaultAddress}
                                permissions={
                                  userSettings.gatewayUserPermissions
                                }
                                showAlertDialogError={showAlertDialogError}
                              />
                            </div>
                          ),
                        },
                        {
                          props: {
                            label: "Transactions",
                            icon: <ShoppingCartIcon />,
                          },
                          panel: (
                            <div>
                              <CustomerTransactions
                                customerId={newCustomerDetails?.customerId}
                              />
                            </div>
                          ),
                        },
                        gatewaySettings["ALLOW_INVOICING"] &&
                        gatewaySettings["CUSTOMER_PAYABLE"] &&
                        (userSettings?.gatewayUserPermissions[
                          "VIEW_CUST_PAYABLE_TOKEN"
                        ] ||
                          userSettings?.gatewayUserPermissions[
                            "MANAGE_CUST_PAYABLE_TOKEN"
                          ])
                          ? {
                              props: {
                                label: "Customer Payables",
                                icon: <LinkIcon />,
                              },
                              panel: (
                                <div
                                  style={{
                                    textAlign: "center",
                                    width: "600px",
                                  }}
                                >
                                  <CVCustomerPayables
                                    userSettings={userSettings}
                                    createUrl={createUrl}
                                    generatedToken={generatedToken}
                                    copyLink={copyLink}
                                  ></CVCustomerPayables>
                                </div>
                              ),
                            }
                          : [],
                        userSettings?.gatewayUserPermissions[
                          "READ_SUBSCRIPTION"
                        ] ||
                        userSettings?.gatewayUserPermissions[
                          "VIEW_ALLSUBSCRIPTIONS"
                        ]
                          ? {
                              props: {
                                label: "Subscriptions",
                                icon: (
                                  <Icon>
                                    <RecurringIcon size={24} />
                                  </Icon>
                                ),
                              },
                              panel: (
                                <div>
                                  <CustomerSubscriptions
                                    subscriptions={
                                      newCustomerDetails.subscriptions
                                    }
                                    onPause={pauseSubscription}
                                    onResume={resumeSubscription}
                                    onCancel={cancelSubscription}
                                  />
                                </div>
                              ),
                            }
                          : [],
                      ]}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="error-container">
                <div className="centered-block">
                  <div className="centered-image">
                    <img src={emptyImg}></img>
                  </div>
                  <p>This customer cannot be found</p>
                </div>
              </div>
            )}
          </div>
        }
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
        alertDialogOpen={alertDialogOpen}
        alertDialogProps={alertDialogProps}
      />
    </FormProvider>
  );
};

export default ViewCustomerContainer;
