import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  FormControlLabel,
  Radio,
  Grid,
  Collapse,
  useTheme,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import CustomerPaymentMethod from "../SubscriptionDetails/CustomerPaymentMethod";
import { TokenizerConfigContext } from "../../../../../contexts/TokenizerConfigContext";
import { PAYMENT_TYPE } from "../../../VirtualTerminal/PaymentTabs";
import ACHTokenizerComponent from "../../../../Tokenizer/ACHTokenizerCmp";
import TokenExComponent from "../../../../Tokenizer/TokenExCmp";
import { iqProVer } from "../../../../../constants/global";
import Tokenizer from "fluidpay-tokenizer";
import { getSettings } from "../../../../../hooks/useTokenizer";
import { CreditCardIcon } from "../../../../../assets/icons/Icons";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const PaymentMethodDialog = ({
  open,
  onClose,
  onSave,
  paymentMethods,
  defaultPaymentMethod,
  showErrorMessage,
  showCard,
  showAch,
}) => {
  const tokenizerSettings = useContext(TokenizerConfigContext);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    defaultPaymentMethod?.paymentMethodId,
  );
  const [add, setAdd] = useState(null);
  const [token, setToken] = useState();
  const [achToken, setAchToken] = useState(null);
  const [achSubmit, setAchSubmit] = useState(false);
  const [cardTokenizer, setCardTokenizer] = useState(null);
  const [achTokenizer, setAchTokenizer] = useState(null);
  const tokenizer = useRef();
  const theme = useTheme();

  useEffect(() => {
    setSelectedPaymentMethodId(defaultPaymentMethod?.paymentMethodId);
  }, [defaultPaymentMethod]);

  useEffect(() => {
    if (achToken) {
      setAchSubmit(false);
      handleTokenizerSubmission({ status: "success", token: achToken });
    }
  }, [achToken]);

  useEffect(() => {
    if (token) {
      onSave(null, token, add);
      setAchToken(null);
    }
  }, [token]);

  useEffect(() => {
    if (
      add === "card" &&
      !cardTokenizer &&
      tokenizerSettings.cardTokenizer === iqProVer.v1
    ) {
      setCardTokenizer(
        new Tokenizer({
          apikey: "pub_2iWYeQBeij7rSnCBB7Q0FswAy8y",
          url: process.env.REACT_APP_FP_BASE_URL,
          container: document.querySelector("#tokenizer-card"),
          submission: (resp) => handleTokenizerSubmission(resp),
          settings: getSettings(PAYMENT_TYPE.CARD, theme.palette.mode, false),
        }),
      );
    } else if (
      add === "ach" &&
      !achTokenizer &&
      tokenizerSettings.achTokenizer === iqProVer.v1
    ) {
      setAchTokenizer(
        new Tokenizer({
          apikey: "pub_2iWYeQBeij7rSnCBB7Q0FswAy8y",
          url: process.env.REACT_APP_FP_BASE_URL,
          container: document.querySelector("#tokenizer-ach"),
          submission: (resp) => handleTokenizerSubmission(resp),
          settings: getSettings(PAYMENT_TYPE.ACH, theme.palette.mode, false),
        }),
      );
    }
  }, [add]);

  const handleSave = () => {
    if (add === "card") {
      if (tokenizerSettings.cardTokenizer === iqProVer.v1) {
        cardTokenizer.submit();
      } else {
        tokenizer?.current?.validate();
      }
    } else if (add === "ach") {
      if (tokenizerSettings.achTokenizer === iqProVer.v1) {
        achTokenizer.submit();
      } else {
        setAchSubmit(true);
      }
    } else {
      onSave(selectedPaymentMethodId);
    }
  };

  const handleCardToken = (data, exp) => {
    setToken({
      token: data.token,
      expirationDate: exp,
      maskedCard: data.firstSix + "******" + data.lastFour,
    });
  };

  const handleTokenizerSubmission = (response) => {
    if (response.status === "success") {
      setToken(response.token);
    } else {
      showErrorMessage(response.msg || "Invalid payment information.");
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      className={`v2 ${theme.palette.mode}-theme`}
      PaperProps={{ className: "subscription-modal" }}
    >
      <DialogTitle>
        <CreditCardIcon />
        {paymentMethods?.length > 0
          ? "Change Payment Method"
          : "Add Payment Method"}
        <IconButton fontSize="18px" onClick={onClose}>
          <CloseOutlinedIcon className="close-button" />
        </IconButton>
      </DialogTitle>
      {paymentMethods?.map((method) => (
        <Grid item xs={12} key={method.paymentMethodId} marginY={1}>
          <FormControlLabel
            className="customer-payment-method-option"
            label={<CustomerPaymentMethod paymentMethod={method} />}
            labelPlacement="end"
            checked={selectedPaymentMethodId === method.paymentMethodId && !add}
            onChange={() => {
              setSelectedPaymentMethodId(method.paymentMethodId);
              setAdd(null);
            }}
            control={<Radio />}
          />
        </Grid>
      ))}
      {showCard && (
        <Grid item xs={12}>
          <FormControlLabel
            className="customer-payment-method-option"
            label="Add a new card"
            labelPlacement="end"
            checked={add === "card"}
            onChange={() => setAdd("card")}
            control={<Radio />}
          />
        </Grid>
      )}
      {showAch && (
        <Grid item xs={12}>
          <FormControlLabel
            className="customer-payment-method-option"
            label="Add a new bank account"
            labelPlacement="end"
            checked={add === "ach"}
            onChange={() => setAdd("ach")}
            control={<Radio />}
          />
        </Grid>
      )}
      {tokenizerSettings.cardTokenizer === iqProVer.v1 ? (
        <Grid
          padding={2.5}
          paddingTop={1.5}
          display={add === "card" ? "block" : "none"}
          className="tokenizer-form"
          id="tokenizer-card"
        ></Grid>
      ) : (
        <Collapse in={add === "card"}>
          <Grid container padding={2.5}>
            <TokenExComponent
              tokenizerRef={tokenizer}
              handleSubmit={handleCardToken}
              tokenConfig={tokenizerSettings?.iframeConfiguration}
              cvvState="hidden"
            />
          </Grid>
        </Collapse>
      )}
      {tokenizerSettings.achTokenizer === iqProVer.v1 ? (
        <Grid
          padding={2.5}
          paddingTop={1.5}
          display={add === "ach" ? "block" : "none"}
          className="tokenizer-form"
          id="tokenizer-ach"
        ></Grid>
      ) : (
        <Collapse in={add === "ach"}>
          <Grid container padding={2.5}>
            <ACHTokenizerComponent
              achSubmit={achSubmit}
              achToken={achToken}
              setAchToken={setAchToken}
            />
          </Grid>
        </Collapse>
      )}
      <Grid container spacing={1} padding={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            className="btn--primary"
            onClick={handleSave}
            disabled={achSubmit}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth className="btn--secondary" onClick={onClose}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

PaymentMethodDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  defaultPaymentMethod: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showCard: PropTypes.bool,
  showAch: PropTypes.bool,
};

export default PaymentMethodDialog;
