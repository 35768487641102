import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./searchList.scss";
import "../../../assets/v2/main.scss";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { FormProvider, useForm } from "react-hook-form";
import Filters from "./Filter/Filters";
import FilterDrawer from "./Filter/Drawer";

const SearchList = ({
  title,
  data,
  fetchData,
  hasMoreData,
  cardProps,
  filters,
  details,
  listClass,
  detailsClass,
  detailsRef,
  activeIndex,
  sortColumns,
  hideSearch,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [sortColumn, setSortColumn] = useState(
    sortColumns?.find((column) => column.isDefault)?.value || null,
  );
  const [sortDirection, setSortDirection] = useState("DESC");
  const [filterValues, setFilterValues] = useState(
    filters?.reduce((values, filter) => {
      values[filter.name] = "";
      return values;
    }, {}) || {},
  );
  const [height, setHeight] = useState(1000);
  const observerTarget = useRef(null);
  const theme = useTheme();
  const isScreenSmall = useMediaQuery((t) => t.breakpoints.down("sm"));
  const methods = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (!data?.length) handleFetchData();
  }, []);

  useEffect(() => {
    if (!detailsRef?.current) return;

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(detailsRef.current);

    return () => resizeObserver.disconnect();
  }, [detailsRef?.current]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 1,
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, openMobileDrawer]);

  useEffect(() => {
    if (isIntersecting && hasMoreData) handleFetchData();
    else if (isIntersecting) setIsLoading(false);
  }, [isIntersecting]);

  const handleObserver = (entries) => {
    setIsIntersecting(entries[0].isIntersecting);
  };

  const handleResize = (entries) => {
    setHeight(entries[0].contentRect.height);
  };

  const handleSortClick = (e) => {
    setOpenSort(true);
    setSortAnchorEl(e.currentTarget);
  };

  const handleSortClose = () => {
    setOpenSort(false);
    setSortAnchorEl(null);
  };

  const handleSortItemClick = (value) => {
    if (sortColumn === value) setSortColumn(null);
    else setSortColumn(value);
  };

  const handleFetchData = () => {
    const searchInput = methods.getValues("searchInput");
    let globalFilters = {};

    Object.entries(filterValues).forEach(([name, value]) => {
      if (value !== "") {
        const filter = filters.find((f) => f.name === name);
        if (name === "globalSearch") globalFilters[name] = value;
        else {
          globalFilters[name] = {
            operator: filter.operator,
          };

          if (filter.type === "date")
            globalFilters[name].date = `${value}T00:00:00.000Z`;
          else globalFilters[name].value = value;
        }
      }
    });

    if (searchInput?.length > 0) globalFilters.globalSearch = searchInput;

    setIsLoading(true);

    fetchData(
      Object.keys(globalFilters).length > 0 ? globalFilters : null,
      sortColumn,
      sortDirection,
    ).then(() => {
      setIsLoading(false);
    });
  };

  const handleSearch = (e) => {
    if (e) e.preventDefault();

    handleFetchData();
    setOpenSort(false);
    setOpenFilterDrawer(false);
  };

  const handleReset = () => {
    setFilterValues(
      filters?.reduce((values, filter) => {
        values[filter.name] = "";
        return values;
      }, {}) || {},
    );
    setOpenFilterDrawer(false);

    handleFetchData();
  };

  const searchList = (
    <List
      className={`search-list ${listClass}`}
      sx={{
        height,
      }}
    >
      <Grid container justifyContent="space-between">
        {title && (
          <Typography variant="h3" id="search-list-title">
            {title}
          </Typography>
        )}
        {sortColumns && (
          <IconButton onClick={handleSortClick}>
            <UnfoldMoreIcon />
          </IconButton>
        )}
      </Grid>
      {!filters?.length && !hideSearch && (
        <FormProvider {...methods}>
          <form onSubmit={handleSearch}>
            <Grid item xs={12} className="search-list-input-container">
              <RegisteredTextField
                v2
                className="search-list-input"
                placeholder="Search"
                name="searchInput"
                autoComplete="off"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Button
                      variant="contained"
                      className="search-list-input-button"
                      onClick={handleSearch}
                    >
                      <SearchIcon htmlColor="#000" fontSize="small" />
                    </Button>
                  ),
                }}
              />
            </Grid>
          </form>
        </FormProvider>
      )}
      {data?.map((item, index) => (
        <Card
          className={`${
            activeIndex === index
              ? "search-list-card active"
              : "search-list-card"
          }`}
          key={index}
          onClick={() => cardProps.onClick(index)}
        >
          <Grid item container>
            <Grid className="card-corner" item xs={6}>
              {cardProps?.getTopLeft && cardProps.getTopLeft(item)}
            </Grid>
            <Grid className="card-corner" item xs={6}>
              {cardProps?.getTopRight && cardProps.getTopRight(item)}
            </Grid>
            <Grid className="card-content" item xs={12}>
              {cardProps.getContent(item)}
            </Grid>
            <Grid className="card-corner" item xs={6}>
              {cardProps?.getBottomLeft && cardProps.getBottomLeft(item)}
            </Grid>
            <Grid className="card-corner" item xs={6}>
              {cardProps?.getBottomRight && cardProps.getBottomRight(item)}
            </Grid>
          </Grid>
        </Card>
      ))}
      {hasMoreData && !isLoading && (
        <Grid container>
          <Grid item container justifyContent="center" alignItems="center">
            <Button variant="text" onClick={handleFetchData}>
              Load More Results
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container className="search-list-loader" ref={observerTarget}>
        {isLoading && (
          <Grid item container justifyContent="center" alignItems="center">
            <p>Loading</p>
            <CircularProgress
              className="loader-icon"
              color="inherit"
              size={15}
            />
          </Grid>
        )}
      </Grid>
    </List>
  );

  const mobileSearchList = (
    <List className={`search-list ${listClass}`}>
      <Grid container justifyContent="space-between">
        {title && (
          <Typography variant="h3" id="search-list-title">
            {title}
          </Typography>
        )}
        <Grid item alignSelf="flex-end">
          <Button
            color="secondary"
            onClick={() => setOpenMobileDrawer(false)}
            endIcon={<KeyboardArrowLeftOutlinedIcon />}
          >
            Close {title}
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        {!hideSearch && (
          <FormProvider {...methods}>
            <form onSubmit={handleSearch} className="search-list-input-form">
              <Grid item xs={12} className="search-list-input-container">
                <RegisteredTextField
                  v2
                  className="search-list-input"
                  placeholder="Search"
                  name="searchInput"
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant="contained"
                        className="search-list-input-button"
                        onClick={handleSearch}
                      >
                        <SearchIcon htmlColor="#000" fontSize="small" />
                      </Button>
                    ),
                  }}
                />
              </Grid>
            </form>
          </FormProvider>
        )}
        {(filters || sortColumns) && (
          <ButtonGroup>
            {filters && (
              <IconButton onClick={() => setOpenFilterDrawer(true)}>
                <FilterAltOutlinedIcon />
              </IconButton>
            )}
            {sortColumns && (
              <IconButton onClick={handleSortClick}>
                <UnfoldMoreIcon />
              </IconButton>
            )}
          </ButtonGroup>
        )}
      </Grid>
      {data?.map((item, index) => (
        <Card
          className={`${
            activeIndex === index
              ? "search-list-card active"
              : "search-list-card"
          }`}
          key={index}
          onClick={() => cardProps.onClick(index)}
        >
          <Grid item container>
            <Grid className="card-corner" item xs={6}>
              {cardProps?.getTopLeft && cardProps.getTopLeft(item)}
            </Grid>
            <Grid className="card-corner" item xs={6}>
              {cardProps?.getTopRight && cardProps.getTopRight(item)}
            </Grid>
            <Grid className="card-content" item xs={12}>
              {cardProps.getContent(item)}
            </Grid>
            <Grid className="card-corner" item xs={6}>
              {cardProps?.getBottomLeft && cardProps.getBottomLeft(item)}
            </Grid>
            <Grid className="card-corner" item xs={6}>
              {cardProps?.getBottomRight && cardProps.getBottomRight(item)}
            </Grid>
          </Grid>
        </Card>
      ))}
      <Grid container className="search-list-loader" ref={observerTarget}>
        {isLoading && (
          <Grid item container justifyContent="center" alignItems="center">
            <p>Loading</p>
            <CircularProgress
              className="loader-icon"
              color="inherit"
              size={15}
            />
          </Grid>
        )}
      </Grid>
    </List>
  );

  return (
    <Grid
      className="search-list-container v2"
      container
      justifyContent="space-between"
    >
      <Popover
        className={`search-list-sort-menu ${theme.palette.mode}-theme`}
        open={openSort}
        onClose={handleSortClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        anchorEl={sortAnchorEl}
      >
        {sortColumns?.map((column, index) => (
          <MenuItem
            key={index}
            value={column.value}
            selected={sortColumn === column.value}
            onClick={() => handleSortItemClick(column.value)}
          >
            {column.label}
          </MenuItem>
        ))}
        <Divider variant="middle" />
        <ButtonGroup>
          <Button
            startIcon={<VerticalAlignTopIcon />}
            color={sortDirection === "ASC" ? "secondary" : "primary"}
            className={sortDirection === "ASC" ? "selected" : null}
            variant="text"
            onClick={() => setSortDirection("ASC")}
          >
            Ascending
          </Button>
          <Button
            startIcon={<VerticalAlignBottomIcon />}
            color={sortDirection === "DESC" ? "secondary" : "primary"}
            className={sortDirection === "DESC" ? "selected" : null}
            variant="text"
            onClick={() => setSortDirection("DESC")}
          >
            Descending
          </Button>
        </ButtonGroup>
        <Grid marginTop={1}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleSearch}
          >
            Apply
          </Button>
        </Grid>
      </Popover>
      {filters?.length && (
        <Grid item container className="search-list-filter-container">
          <FormProvider {...methods}>
            <form onSubmit={handleSearch}>
              {!isScreenSmall && (
                <Filters
                  filters={filters}
                  values={filterValues}
                  setValues={setFilterValues}
                  onSearch={handleSearch}
                  setOpenDrawer={setOpenFilterDrawer}
                />
              )}
              <FilterDrawer
                filters={filters}
                values={filterValues}
                setValues={setFilterValues}
                open={openFilterDrawer}
                setOpenDrawer={setOpenFilterDrawer}
                onReset={handleReset}
                onFilter={handleSearch}
              />
            </form>
          </FormProvider>
        </Grid>
      )}
      {!isScreenSmall ? (
        <Grid item container sm={3} className="search-list-container">
          <Drawer variant={isScreenSmall ? "temporary" : "permanent"}>
            {searchList}
          </Drawer>
        </Grid>
      ) : (
        <>
          {!openMobileDrawer && (
            <Grid item container>
              <Button
                color="secondary"
                onClick={() => setOpenMobileDrawer(true)}
                endIcon={<KeyboardArrowRightOutlinedIcon />}
              >
                Open {title}
              </Button>
            </Grid>
          )}
          <Drawer
            open={openMobileDrawer}
            onClose={() => setOpenMobileDrawer(false)}
            variant={isScreenSmall ? "persistent" : "permanent"}
            sx={{
              display: openMobileDrawer ? "block" : "none",
            }}
          >
            {mobileSearchList}
          </Drawer>
        </>
      )}
      {!openMobileDrawer && (
        <Grid
          item
          container
          xs={12}
          sm={8.7}
          className="search-list-details-container"
        >
          <Grid item xs={12} className={`search-list-details ${detailsClass}`}>
            {details}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

SearchList.propTypes = {
  hideSearch: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  fetchData: PropTypes.func,
  hasMoreData: PropTypes.bool,
  details: PropTypes.node,
  listClass: PropTypes.string,
  detailsClass: PropTypes.string,
  detailsRef: PropTypes.object,
  activeIndex: PropTypes.number,
  cardProps: PropTypes.shape({
    onClick: PropTypes.func,
    getContent: PropTypes.func,
    getTopLeft: PropTypes.func,
    getTopRight: PropTypes.func,
    getBottomLeft: PropTypes.func,
    getBottomRight: PropTypes.func,
  }).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "date", "checkbox", "select"]).isRequired,
      toolbar: PropTypes.bool.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    }),
  ),
  sortColumns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isDefault: PropTypes.bool,
    }),
  ),
};

SearchList.defaultProps = {
  hideSearch: false,
  listClass: "",
  detailsClass: "",
  detailsRef: null,
};

export default SearchList;
