import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Link,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchTable from "../../../../Search/SearchTable";
import { UserSettingsContext } from "../../../../../contexts/UserSettingsContext";
import { parseISO } from "date-fns";
import { merchantRoutes } from "../../../../../constants/routes";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { formatDateTime } from "../../../../../utils/stringHelpers";

const InvoiceTable = ({ loading, subscription, onViewAsPDF }) => {
  const { userSettings } = useContext(UserSettingsContext);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const actionOpen = Boolean(actionAnchorEl);
  const history = useHistory();
  const fieldList = [
    { key: "invoice_id" },
    { key: "status" },
    { key: "remaining_balance" },
    { key: "due_date" },
    { key: "created_on" },
  ];

  const getInvoicesKeyValue = (key, data, userSettings) => {
    const invoiceId =
      data?.invoiceId?.length > 8
        ? data.invoiceId.slice(0, 4) + "..." + data.invoiceId.slice(-4)
        : data?.invoiceId;

    switch (key) {
      case "invoice_id":
        return userSettings?.gatewayUserPermissions["READ_INVOICE"] ? (
          <Link
            color="secondary"
            component={RouterLink}
            to={`${merchantRoutes.invoices.base}/${data.invoiceId}`}
          >
            {invoiceId}
          </Link>
        ) : (
          invoiceId
        );

      case "remaining_balance": {
        return `$${data?.remainingBalance?.toFixed(2) || "0.00"}`;
      }

      case "due_date": {
        return formatDateTime(data?.dueDate).split(" ")[0];
      }
      case "created_on": {
        return formatDateTime(data?.createdDateTime).split(" ")[0];
      }

      case "status": {
        return statusTag(data?.status?.name);
      }

      default:
        return data[key];
    }
  };

  const statusTag = (status) => {
    let tagClass;
    switch (status) {
      case "Draft":
        tagClass = "tag__grey";
        break;
      case "Sent":
        tagClass = "tag__blue";
        break;
      case "Paid":
        tagClass = "tag__success";
        break;
      case "Cancelled":
        tagClass = "tag__yellow";
        break;
      case "Overdue":
        tagClass = "tag__warning";
        break;
    }

    return (
      <div className={`tag invoice-status ${tagClass}`}>
        {status?.toUpperCase()}
      </div>
    );
  };

  const handleClick = (e, row) => {
    setSelectedInvoice(row);
    setActionAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setActionAnchorEl(null);
  };

  const handleViewAsPDF = (invoiceId) => {
    setActionAnchorEl(null);
    onViewAsPDF(invoiceId);
  };

  const getActionsComponent = (row) => {
    return (
      <IconButton aria-label="options" onClick={(e) => handleClick(e, row)}>
        <MoreVertIcon />
      </IconButton>
    );
  };

  return (
    <Grid container>
      <Grid item marginY={2}>
        <Typography variant="h3">
          <strong>Invoices</strong>
          {loading && <CircularProgress className="loading-icon" size={15} />}
        </Typography>
      </Grid>
      <Grid
        item
        container
        className="subscription-details"
        rowSpacing={2}
        columnSpacing={1}
      >
        <Grid
          item
          container
          xs={12}
          sm={3}
          className="subscription-summary-field"
        >
          <Grid item xs={12}>
            <Typography variant="body1">Invoice Prefix</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>{subscription.prefix}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={3}
          className="subscription-summary-field"
        >
          <Grid item xs={12}>
            <Typography variant="body1">Invoice Terms</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>Net {subscription.recurrence.invoiceLengthInDays}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className="subscription-summary-field">
          <Grid>
            <Typography variant="body1">Invoice Message</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className="invoice-message">
              <strong>{subscription?.message}</strong>
            </Typography>
          </Grid>
        </Grid>
        {subscription.invoices?.length > 0 && (
          <SearchTable
            searchResults={subscription.invoices.sort((a, b) => {
              return parseISO(a.createdDateTime) - parseISO(b.createdDateTime);
            })}
            fieldList={fieldList}
            getActionsComponent={getActionsComponent}
            getEntityKeyValue={(key, data) =>
              getInvoicesKeyValue(key, data, userSettings)
            }
          />
        )}
      </Grid>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        className="action-menu"
        anchorEl={actionAnchorEl}
        open={actionOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {userSettings?.gatewayUserPermissions["READ_INVOICE"] && (
          <MenuItem
            onClick={() =>
              history.push(
                `${merchantRoutes.invoices.base}/${selectedInvoice.invoiceId}`,
              )
            }
          >
            View Invoice
          </MenuItem>
        )}

        {selectedInvoice?.status.name !== "Draft" &&
          selectedInvoice?.status.name !== "Cancelled" && (
            <MenuItem
              onClick={() => handleViewAsPDF(selectedInvoice.invoiceId)}
            >
              View Invoice PDF
            </MenuItem>
          )}
        {selectedInvoice?.status.name === "Paid" && (
          <MenuItem
            onClick={() =>
              history.push(merchantRoutes.reporting.transactions, {
                invoiceId: selectedInvoice?.invoiceId,
              })
            }
          >
            View Invoice Transaction(s)
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
};

InvoiceTable.propTypes = {
  subscription: PropTypes.object,
  onViewAsPDF: PropTypes.func,
  loading: PropTypes.bool,
};

export default InvoiceTable;
