import React from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Tooltip, Typography } from "@mui/material";

const LabelWithTooltip = ({ label, tooltip, placement, v2 }) => {
  return (
    <React.Fragment>
      <Typography variant="body1" className="label-with-tooltip-label">
        {label}
      </Typography>
      <Tooltip title={tooltip} placement={placement}>
        {v2 ? (
          <ErrorOutlineOutlinedIcon
            color="secondary"
            fontSize="small"
            className="label-with-tooltip-icon v2"
          />
        ) : (
          <InfoIcon fontSize="small" className="label-with-tooltip-icon" />
        )}
      </Tooltip>
    </React.Fragment>
  );
};

LabelWithTooltip.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  placement: PropTypes.string,
  v2: PropTypes.bool,
};

LabelWithTooltip.defaultProps = {
  placement: "top",
  v2: false,
};

export default LabelWithTooltip;
