import React, { useContext, useEffect, useState } from "react";
import { Alert, Grid, IconButton, Typography } from "@mui/material";
import { useParams, useHistory, useLocation } from "react-router-dom";
import CustomerDetails from "../InvoicesCreate/StepOne/InvoiceStepOne";
import InvoiceDetails, {
  formatDate,
} from "../InvoicesCreate/StepTwo/InvoiceStepTwo";
import LineItemDetails from "../InvoicesCreate/StepThree/InvoiceStepThree";
import axios from "axios";
import { sassEndpoints } from "../../../constants/endpoints";
import { useAlertDialog, useAxios } from "../../../hooks";
import {
  generalError,
  merchantRoutes,
  noProcessors,
} from "../../../constants/routes";
import { CountriesContext } from "../../../contexts/CountriesContext";
import { GatewayContext } from "../../../contexts/GatewayContext";
import Header from "../InvoicesCreate/Header/Header";
import PreventNavigationDialog from "../InvoicesCreate/PreventNavigation/PreventNavigationDialog";
import ContentComponent from "../../Content/Content";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditOffOutlinedIcon from "@mui/icons-material/EditOffOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { stringFormat } from "../../../utils/stringHelpers";
import {
  CONTACT_SUPPORT,
  INVOICE_STATUSES,
  iqProVer,
} from "../../../constants/global";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { TokenizerConfigContext } from "../../../contexts/TokenizerConfigContext";
import { getStatusColors } from "../InvoicesSearch/InvoicesTableHelpers";

var _ = require("lodash");

const InvoicesEditContainer = () => {
  const { isAlertOpen, setIsAlertOpen } = useAlertDialog();
  const { id } = useParams();
  const countries = useContext(CountriesContext);
  const gateway = useContext(GatewayContext);
  const { userSettings } = useContext(UserSettingsContext);
  const tokenizerConfig = useContext(TokenizerConfigContext);
  const history = useHistory();
  const location = useLocation();
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [editing, setEditing] = useState(location?.state?.edit);
  const [fieldFocused, setFieldFocused] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [payload, setPayload] = useState({});
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [copyBillingAddress, setCopyBillingAddress] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [vaultCustomer, setVaultCustomer] = useState(false);
  const [remittanceAddress, setRemitAddress] = useState(null);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [processors, setProcessors] = useState([]);
  const [processorsLoading, setProcessorsLoading] = useState(false);
  const [customFieldGroups, setCustomFieldGroups] = useState(null);
  const [chosenCustomFields, setChosenCustomFields] = useState(null);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(false);
  const { data: unitsOfMeasure, loading: unitsLoading } = useAxios(
    {
      method: "GET",
      url: sassEndpoints.invoices.unitsOfMeasure,
    },
    { initialData: [], dataTransform: (data) => data.data },
  );

  useEffect(() => {
    if (!invoice) {
      loadInvoice();
    }

    loadProcessors();
    loadCustomFields();
  }, []);

  const loadInvoice = () => {
    const url = stringFormat(`${sassEndpoints.invoices.invoice}/${id}`, [
      gateway.gatewayId,
    ]);

    setInvoiceLoading(true);

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          var invoice = response.data.data;

          if (
            invoice.invoiceNotifications.length > 0 &&
            (invoice.invoiceNotifications[0].notificationStatus.name !==
              "Cancelled" ||
              invoice.invoiceStatus.name === "Cancelled")
          )
            invoice.invoiceNotifications[0].invoiceNotificationMethodId =
              invoice?.invoiceNotifications[0]?.notificationMethod?.invoiceNotificationMethodId;
          else invoice.invoiceNotifications = [];

          setInvoice(invoice);
          setRemitAddress(invoice.invoiceAddresses.find((i) => i.isRemittance));
          setInvoiceLoading(false);

          if (invoice.customer && !customer)
            loadCustomer(invoice.customer.customerId);
        } else history.push(generalError);
      })
      .catch(() => {
        setInvoiceLoading(false);
        history.push(generalError);
      });
  };

  const loadCustomer = (customerId) => {
    const url = stringFormat(sassEndpoints.customers.customerId, [
      gateway.gatewayId,
      customerId,
    ]);

    setCustomerLoading(true);

    return axios
      .get(url)
      .then((response) => {
        setCustomerLoading(false);
        setCustomer(response.data.data);

        return response.data.data;
      })
      .catch(() => {
        setCustomerLoading(false);
        displayMessage([
          "Failed to load customer information.",
          CONTACT_SUPPORT,
        ]);
      });
  };

  const loadProcessors = () => {
    const url = stringFormat(sassEndpoints.processors.processor, [
      gateway.gatewayId,
    ]);

    setProcessorsLoading(true);

    axios
      .get(url)
      .then((response) => {
        if (response.status === 204) history.push(noProcessors);
        else {
          setProcessors(response?.data?.data || []);
          setProcessorsLoading(false);
        }
      })
      .catch(() => {
        setProcessorsLoading(false);
        displayMessage(["Failed to load processors.", CONTACT_SUPPORT]);
      });
  };

  const loadCustomFields = () => {
    const url = stringFormat(sassEndpoints.customFields.category, [
      gateway.gatewayId,
    ]);

    setCustomFieldsLoading(true);

    axios
      .get(url)
      .then((response) => {
        if (response.status !== 204) {
          let invoiceCustomFields = response.data?.data?.filter(
            (cf) => cf.invoice,
          );
          let groupNames = invoiceCustomFields.map((group) => group.name);
          let customFields = invoiceCustomFields.map(
            (fields) => fields.customFields,
          );

          setCustomFieldGroups(groupNames);
          setChosenCustomFields(customFields);
        }
      })
      .catch(() => {
        displayMessage(["Failed to load custom fields", CONTACT_SUPPORT]);
      })
      .finally(() => {
        setCustomFieldsLoading(false);
      });
  };

  const handleEditClick = () => {
    if (payload && editing) {
      window.history.replaceState({}, "");
      window.location.reload();
    } else setEditing(!editing);
  };

  const mapAddress = (address, isBilling) => {
    if (!address) return null;

    // Only save address info if at least one value was changed
    let finalAddress = Object.entries(address).reduce((a, [key, value]) => {
      if (key !== "country" && value?.length) {
        if (!a) a = { isBilling, isShipping: !isBilling };
        a[key] = value;
      }

      return a;
    }, null);

    // Only save country if it was changed or if other values were provided
    if (
      finalAddress ||
      (!isBilling && copyBillingAddress) ||
      invoice?.invoiceAddresses.find((a) => a?.isBilling === isBilling)?.country
    ) {
      if (!finalAddress) finalAddress = { isBilling, isShipping: !isBilling };
      finalAddress.country = address.country;
    }

    return finalAddress;
  };

  const saveCustomerChanges = (
    values,
    dirtyFields,
    requireBillingInfo,
    requireShippingInfo,
  ) => {
    if (
      (invoice.invoiceStatus.invoiceStatusId === INVOICE_STATUSES.SENT ||
        invoice.invoiceStatus.invoiceStatusId === INVOICE_STATUSES.OVERDUE) &&
      Object.keys(dirtyFields).length > 0
    ) {
      const invoiceAddresses = [];
      const billingAddress = mapAddress(values.billing, true);
      const shippingAddress = mapAddress(values.shipping, false);

      if (billingAddress) invoiceAddresses.push(billingAddress);
      if (shippingAddress) invoiceAddresses.push(shippingAddress);

      const customerInfo = {
        ...payload,
        customerId: customer?.customerId,
        requireBillingInfo,
        requireShippingInfo,
        invoiceNotifications:
          values.invoiceNotification.invoiceNotificationMethodId !== 0
            ? [
                {
                  invoiceNotificationMethodId:
                    values.invoiceNotification.invoiceNotificationMethodId,
                  mainRecipient: values.billing.email,
                  cc:
                    values.invoiceNotification.cc &&
                    values.invoiceNotification.cc !== ""
                      ? values.invoiceNotification.cc
                          .split(",")
                          .map((email) => email.trim())
                      : [],
                  bcc:
                    values.invoiceNotification.bcc &&
                    values.invoiceNotification.bcc !== ""
                      ? values.invoiceNotification.bcc
                          .split(",")
                          .map((email) => email.trim())
                      : [],
                },
              ]
            : [],
        invoiceAddresses,
      };

      setPayload(customerInfo);
      setFieldFocused(false);
      setFormIsDirty(true);
      setFieldError(false);

      return;
    }

    Object.keys(dirtyFields).forEach((field) => {
      Object.keys(dirtyFields[field]).forEach((property) => {
        if (field === "billing" || field === "shipping") {
          if (!payload.invoiceAddresses) payload.invoiceAddresses = [];
          var isBilling = field === "billing";
          var isShipping = field === "shipping";
          var savedAddress = invoice.invoiceAddresses.find(
            (a) =>
              a?.isBilling === isBilling &&
              a?.isShipping === isShipping &&
              !a.deleteAddress,
          );
          var newAddress = payload.invoiceAddresses.find(
            (a) => a?.isBilling === isBilling && a?.isShipping === isShipping,
          );

          if (!savedAddress) {
            if (!newAddress) {
              newAddress = {
                isBilling,
                isShipping,
                isRemittance: false,
                country: values[field].country,
              };
            }
          } else {
            if (!newAddress) {
              newAddress = {
                invoiceAddressId: savedAddress.invoiceAddressId,
                isBilling,
                isShipping,
                isRemittance: false,
              };
            }
          }

          if (
            field === "billing" &&
            property === "email" &&
            values.invoiceNotification?.invoiceNotificationMethodId === 1
          ) {
            if (!payload.invoiceNotifications)
              payload.invoiceNotifications = [];

            let savedNotification = invoice.invoiceNotifications[0];
            let newNotification = payload.invoiceNotifications[0];

            if (savedNotification && !newNotification) {
              newNotification = {
                invoiceNotificationGroupId:
                  savedNotification.invoiceNotificationGroupId,
                invoiceNotificationMethodId:
                  values.invoiceNotification?.invoiceNotificationMethodId,
                cc: values.invoiceNotification?.cc.split(","),
                bcc: values.invoiceNotification?.bcc.split(","),
              };
            }

            if (!newNotification) newNotification = {};
            newNotification.mainRecipient = values[field][property];

            if (!payload.invoiceNotifications[0])
              payload.invoiceNotifications.push(newNotification);
          }

          newAddress[property] = values[field][property];

          // Only push new address if one of the fields was changed
          if (
            Object.keys(newAddress).length > 4 &&
            !payload.invoiceAddresses.find(
              (a) => a?.isBilling === isBilling && a?.isShipping === isShipping,
            )
          )
            payload.invoiceAddresses.push(newAddress);
        }

        if (field === "invoiceNotification") {
          if (!payload.invoiceNotifications) payload.invoiceNotifications = [];

          let savedNotification = invoice.invoiceNotifications[0];
          let newNotification = payload.invoiceNotifications[0];

          if (savedNotification && !newNotification) {
            newNotification = {
              invoiceNotificationGroupId:
                savedNotification.invoiceNotificationGroupId,
            };
          }

          if (!newNotification) newNotification = {};

          if (
            property === "invoiceNotificationMethodId" &&
            values[field][property] === 0
          )
            newNotification.deleteInvoiceNotification = true;
          else {
            if (
              property === "invoiceNotificationMethodId" &&
              values[field][property] === 1
            )
              delete newNotification.deleteInvoiceNotification;

            newNotification[property] = values[field][property];
          }

          if (!newNotification.mainRecipient)
            newNotification.mainRecipient = values.billing.email;

          if (!newNotification.cc) newNotification.cc = [];
          else newNotification.cc = [newNotification.cc].flat();

          if (!newNotification.bcc) newNotification.bcc = [];
          else newNotification.bcc = [newNotification.bcc].flat();

          if (!payload.invoiceNotifications[0])
            payload.invoiceNotifications.push(newNotification);
        }
      });
    });

    if (requireBillingInfo !== invoice.requireBillingInfo)
      payload.requireBillingInfo = requireBillingInfo;
    if (requireShippingInfo !== invoice.requireShippingInfo)
      payload.requireShippingInfo = requireShippingInfo;

    if (customer && customer.customerId !== invoice.customer?.customerId) {
      payload.customerId = customer.customerId;
      payload.deleteCustomer = false;
    }

    if (editing && invoice.customer && !customer) {
      payload.deleteCustomer = true;
    }

    setPayload(payload);
    setFormIsDirty(Object.keys(payload).length > 0);
    setFieldFocused(false);
    setFieldError(false);
  };

  const saveInvoiceChanges = (values, dirtyFields, card, ach, customFields) => {
    if (
      invoice.invoiceStatus.invoiceStatusId === INVOICE_STATUSES.SENT ||
      invoice.invoiceStatus.invoiceStatusId === INVOICE_STATUSES.OVERDUE
    ) {
      let {
        title,
        number,
        invoiceDate,
        dueDate,
        invoicePaymentMethod,
        message,
        allowPartialPayments,
      } = values;

      if (!invoiceDate) invoiceDate = formatDate(invoice.invoiceDate);
      if (!invoicePaymentMethod) invoicePaymentMethod = {};

      if (card) {
        invoicePaymentMethod.card = card;
        if (!invoicePaymentMethod.cardProcessorId)
          invoicePaymentMethod.cardProcessorId =
            invoice?.invoicePaymentMethod?.cardProcessorId;
      } else {
        invoicePaymentMethod.card = false;
        invoicePaymentMethod.cardProcessorId = null;
      }

      if (ach) {
        invoicePaymentMethod.ach = ach;
        invoicePaymentMethod.secCode = 2; // Default to WEB SEC Code
        if (!invoicePaymentMethod.achProcessorId)
          invoicePaymentMethod.achProcessorId =
            invoice?.invoicePaymentMethod?.achProcessorId;
      } else {
        invoicePaymentMethod.ach = false;
        invoicePaymentMethod.achProcessorId = null;
      }

      const i = {
        ...payload,
        title,
        number: number.length ? number : null,
        invoiceDate,
        dueDate,
        invoicePaymentMethod,
        message,
        customFields,
        allowPartialPayments,
      };

      setPayload(i);
      setFormIsDirty(true);
      setFieldFocused(false);
      setFieldError(false);

      return;
    }

    Object.keys(dirtyFields).forEach((field) => {
      if (field === "invoicePaymentMethod") {
        if (!payload.invoicePaymentMethod) payload.invoicePaymentMethod = {};
        Object.keys(dirtyFields[field]).forEach((property) => {
          if (dirtyFields[field][property] === true)
            payload[field][property] = values[field][property];
          else delete payload[field][property];
        });
      } else if (field === "term") {
        payload.dueDate = values.dueDate;
      } else {
        if (dirtyFields[field] === true) payload[field] = values[field];
        else delete payload[field];
      }
    });

    if (values.invoiceDate) payload.invoiceDate = values.invoiceDate;
    if (values.dueDate) payload.dueDate = values.dueDate;

    payload.invoicePaymentMethod = {
      cardProcessorId: card
        ? payload.invoicePaymentMethod?.cardProcessorId ||
          values.invoicePaymentMethod?.cardProcessorId ||
          invoice.invoicePaymentMethod?.cardProcessorId
        : null,
      achProcessorId: ach
        ? payload.invoicePaymentMethod?.achProcessorId ||
          values.invoicePaymentMethod?.achProcessorId ||
          invoice.invoicePaymentMethod?.achProcessorId
        : null,
      card,
      ach,
    };

    payload.customFields = customFields;

    setPayload(payload);
    setFormIsDirty(Object.keys(payload).length > 0);
    setFieldFocused(false);
    setFieldError(false);
  };

  const saveLineItemChanges = (values) => {
    if (
      invoice.invoiceStatus.invoiceStatusId === INVOICE_STATUSES.SENT ||
      invoice.invoiceStatus.invoiceStatusId === INVOICE_STATUSES.OVERDUE
    ) {
      const invoice = {
        ...payload,
        ...values,
      };

      setPayload(invoice);
      setFormIsDirty(true);

      return;
    }

    let lineItems = payload.lineItems?.filter((li) => li.deleteLineItem) || [];

    values.lineItems.forEach((lineItem, index) => {
      let differentKeys = getObjectDiff(
        lineItem,
        invoice.lineItems[index] || {},
      );
      let changedLineItem = {};

      if (lineItems?.length > 0) index += lineItems.length;
      if (lineItem.lineItemId) changedLineItem.lineItemId = lineItem.lineItemId;

      if (!lineItem.deleteLineItem)
        differentKeys.forEach((key) => {
          changedLineItem[key] = lineItem[key];
        });
      else changedLineItem.deleteLineItem = true;

      if (!lineItem.lineItemId) {
        if (!changedLineItem.unitPrice)
          changedLineItem.unitPrice = lineItem.unitPrice;
        if (!changedLineItem.quantity) changedLineItem.quantity = 1;

        if (!changedLineItem.discount)
          changedLineItem.discount = lineItem.discount || 0;

        if (!changedLineItem.isTaxable) {
          changedLineItem.isTaxable = lineItem.isTaxable || false;
          changedLineItem.localTaxPercent = lineItem.localTaxPercent || 0;
          changedLineItem.nationalTaxPercent = lineItem.nationalTaxPercent || 0;
        }
      }

      if (Object.keys(changedLineItem).length > 0) {
        lineItems.push(changedLineItem);
      }
    });

    payload.lineItems = lineItems;
    setPayload(payload);
    setFormIsDirty(Object.keys(payload).length > 0);
    setFieldFocused(false);
  };

  const getObjectDiff = (obj1, obj2) => {
    return Object.keys(obj1).reduce((result, key) => {
      if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
        result.push(key);
      } else if (_.isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);

        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
  };

  const lineItemsAreEqual = (lineItem1, lineItem2) => {
    var equal = true;
    Object.keys(lineItem1).forEach((key) => {
      if (lineItem1[key] !== lineItem2[key]) equal = false;
    });

    return equal;
  };

  const handleLineItemDelete = (lineItem, index) => {
    if (
      invoice.invoiceStatus.invoiceStatusId !== INVOICE_STATUSES.SENT ||
      invoice.invoiceStatus.invoiceStatusId !== INVOICE_STATUSES.OVERDUE
    ) {
      if (!payload.lineItems) payload.lineItems = [];

      const savedLineItemIndex = payload.lineItems.findIndex((li) =>
        lineItem.lineItemId
          ? li.lineItemId === lineItem.lineItemId
          : lineItemsAreEqual(li, lineItem),
      );

      if (savedLineItemIndex === -1) {
        payload.lineItems.push({
          lineItemId: lineItem.lineItemId,
          deleteLineItem: true,
        });
      } else if (lineItem.lineItemId) {
        payload.lineItems[savedLineItemIndex] = {
          lineItemId: lineItem.lineItemId,
          deleteLineItem: true,
        };
      } else {
        payload.lineItems = payload.lineItems.splice(index, 1);
      }

      setPayload(payload);
      setFormIsDirty(Object.keys(payload).length > 0);
      setFieldFocused(false);
    }
  };

  const handleSelectCustomer = (customerId) => {
    if (!customerId) {
      payload.customerId = null;
      payload.deleteCustomer = true;
      const addresses =
        payload.invoiceAddresses?.filter((a) => !a.isRemittance) ||
        invoice.invoiceAddresses?.filter((a) => !a.isRemittance);

      if (addresses) {
        addresses.forEach((a) => (a.deleteAddress = true));
      }

      payload.invoiceAddresses = addresses;

      setPayload(payload);
      setFormIsDirty(true);
      setFieldFocused(false);
      setCustomer(null);
      return null;
    }

    return loadCustomer(customerId);
  };

  const handleRemitAddressChange = (remitAddress) => {
    if (!payload.invoiceAddresses) payload.invoiceAddresses = [];

    const index = payload.invoiceAddresses.findIndex((a) => a.isRemittance);
    remitAddress.invoiceAddressId = remittanceAddress.invoiceAddressId;

    if (index === -1) payload.invoiceAddresses.push(remitAddress);
    else payload.invoiceAddresses[index] = remitAddress;

    setRemitAddress(remitAddress);
    setPayload(payload);
    setFormIsDirty(true);
  };

  const formatPostInvoicePayload = ({ invoiceStatusId, invoiceTypeId }) => {
    let postPayload = Object.assign(Object.assign(invoice, payload), {
      invoiceStatusId,
      invoiceTypeId,
    });
    const index = postPayload.invoiceAddresses.findIndex((a) => a.isRemittance);

    if (index === -1) postPayload.invoiceAddresses.push(remittanceAddress);
    else postPayload.invoiceAddresses[index] = remittanceAddress;
    postPayload.vaultCustomer = vaultCustomer;
    postPayload.customerId = customer?.customerId;

    if (!postPayload.customFields) {
      postPayload.customFields = [];

      invoice.customFieldEntries.forEach((cf) => {
        var customField = postPayload.customFields.find(
          (field) => field.customFieldId === cf.customFieldId,
        );

        if (customField) {
          customField.customFieldValue.push(cf.value);
        } else {
          postPayload.customFields.push({
            customFieldId: cf.customFieldId,
            customFieldValue: [cf.value],
          });
        }
      });
    }

    return postPayload;
  };

  const postInvoice = (values) => {
    const url = stringFormat(sassEndpoints.invoices.invoice, [
      gateway.gatewayId,
    ]);

    setSubmitting(true);

    axios
      .post(url, formatPostInvoicePayload(values))
      .then((response) => {
        if (response.status === 201 || response.status === 207)
          deleteInvoice(values.invoiceStatusId);
      })
      .catch((error) => {
        displayMessage(
          error?.response?.data?.statusDetails || [
            "Failed to create new invoice",
            CONTACT_SUPPORT,
          ],
        );
        setSubmitting(false);
      });
  };

  const deleteInvoice = (invoiceStatusId) => {
    const url = stringFormat(sassEndpoints.invoices.delete, [
      gateway.gatewayId,
      id,
    ]);

    axios
      .delete(url)
      .then((response) => {
        if (response.status === 204) {
          let success = "New Invoice Created";

          if (invoiceStatusId === INVOICE_STATUSES.SENT)
            success +=
              ": Please allow up to an hour for notification to be sent";

          history.push(merchantRoutes.invoices.search, {
            success,
          });
        } else {
          setAlertDialogProps({
            ...alertDialogProps,
            alertMessages: response.data.statusDetails,
            onCloseButtonClick: () => history.go(0),
          });
          setIsAlertOpen(true);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        displayMessage(
          error?.response?.data?.statusDetails || [
            "Failed to cancel original invoice",
            CONTACT_SUPPORT,
          ],
        );
        setSubmitting(false);
      });
  };

  const patchInvoice = ({
    remainingBalance,
    subtotal,
    discounts,
    tax,
    invoiceStatusId,
  }) => {
    const url = stringFormat(sassEndpoints.invoices.invoice, [
      gateway.gatewayId,
    ]);

    setSubmitting(true);

    if (invoiceStatusId !== invoice.invoiceStatus.invoiceStatusId)
      payload.invoiceStatusId = invoiceStatusId;

    payload.invoiceTypeId = invoice.invoiceType.invoiceTypeId;

    if (payload.lineItems) {
      payload.remainingBalance = remainingBalance;
      payload.total = remainingBalance;
      payload.subtotal = subtotal;
      payload.discounts = discounts;
      payload.tax = tax;

      if (payload.invoiceStatusId === INVOICE_STATUSES.PAID) {
        payload.capturedAmount = remainingBalance;
        payload.remainingBalance = 0.0;
      }
    }

    axios
      .patch(url, [
        {
          op: "replace",
          path: invoice.invoiceId,
          value: payload,
        },
      ])
      .then((response) => {
        if (response.status === 200) {
          let success = "Invoice Updated";

          if (payload.invoiceStatusId === INVOICE_STATUSES.SENT)
            success +=
              ": Please allow up to an hour for notification to be sent";

          history.push(merchantRoutes.invoices.search, {
            success,
          });
        } else {
          setAlertDialogProps({
            ...alertDialogProps,
            alertMessages: response.data.statusDetails,
            onCloseButtonClick: () => history.go(0),
          });
          setIsAlertOpen(true);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        displayMessage(
          error?.response?.data?.statusDetails || [
            "Failed to update invoice",
            CONTACT_SUPPORT,
          ],
        );
        setSubmitting(false);
      });
  };

  const handleSubmit = (values) => {
    const { invoiceStatusId } = invoice.invoiceStatus;

    if (
      invoiceStatusId === INVOICE_STATUSES.SENT ||
      invoiceStatusId === INVOICE_STATUSES.OVERDUE
    ) {
      setAlertDialogProps({
        alertTitle: "Are you sure?",
        alertLevel: "info",
        alertMessages: [
          "This will cancel the original invoice and create a new one. This action cannot be updated or undone.",
        ],
        actionButtons: [
          {
            text: "Yes, Create Invoice!",
            color: "secondary",
            onclick: () => postInvoice(values),
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: () => setIsAlertOpen(false),
      });
      setIsAlertOpen(true);
    } else patchInvoice(values);
  };

  const displayMessage = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setIsAlertOpen(false),
    });
    setIsAlertOpen(true);
  };

  const canEdit =
    (invoice?.createdByUserId === userSettings.user.userId ||
      userSettings.role.code === "SU" ||
      userSettings.role.code === "SA") &&
    (invoice?.invoiceStatus.invoiceStatusId < 4 ||
      invoice?.invoiceStatus.invoiceStatusId === 7);

  return (
    <ContentComponent
      title={editing ? "Edit Invoice" : "View Invoice"}
      useFixedWidth={false}
      spinnerOpen={
        unitsLoading ||
        customerLoading ||
        invoiceLoading ||
        processorsLoading ||
        customFieldsLoading ||
        submitting
      }
      bodyContent={
        <>
          {invoice?.invoiceNotifications?.find(
            (n) =>
              n.notificationEventType?.name === "Created" &&
              n.notificationStatus?.name === "Failed",
          ) && (
            <Alert severity="error">
              Failed to send invoice to customer. {CONTACT_SUPPORT}
            </Alert>
          )}
          {remittanceAddress ? (
            <Header
              countries={countries}
              remittanceAddress={remittanceAddress}
              setRemitAddress={handleRemitAddressChange}
              displayMessages={displayMessage}
              viewOnly={!editing}
            />
          ) : null}
          {!invoiceLoading && countries && (
            <Grid container spacing={2} className="invoice-edit-container">
              <Grid container alignItems="center" paddingY={2}>
                {canEdit && (
                  <>
                    <Typography
                      variant="h6"
                      align="left"
                      className="invoice-section-heading"
                      fontSize={24}
                    >
                      {editing ? "Cancel" : "Edit"}
                    </Typography>
                    <IconButton
                      title="Edit"
                      className="edit-button"
                      onClick={handleEditClick}
                    >
                      {editing ? (
                        <EditOffOutlinedIcon color="warning" />
                      ) : (
                        <EditOutlinedIcon color="warning" />
                      )}
                    </IconButton>
                  </>
                )}
              </Grid>
              <Grid item container md={6}>
                <Grid item container marginBottom={editing ? 0 : 4}>
                  <Typography
                    variant="h6"
                    align="left"
                    className="invoice-section-heading"
                  >
                    Customer Details
                  </Typography>
                </Grid>
                <CustomerDetails
                  countries={countries}
                  copyBillingAddress={copyBillingAddress}
                  setCopyBillingAddress={setCopyBillingAddress}
                  vaultCustomer={vaultCustomer}
                  setVaultCustomer={setVaultCustomer}
                  showVaultCustomer={
                    gateway?.gatewaySettings
                      .find((s) => s.code === "ALLOW_CUSTOMER_VAULT_ACCESS")
                      .value.toLowerCase() === "true" &&
                    tokenizerConfig.cardTokenizer.toUpperCase() !==
                      iqProVer.v1 &&
                    tokenizerConfig.achTokenizer.toUpperCase() !== iqProVer.v1
                  }
                  disableRequireBillingInfo={
                    gateway?.gatewaySettings
                      .find((s) => s.code === "ALLOW_SURCHARGE")
                      ?.value.toLowerCase() === "true"
                  }
                  customer={customer}
                  onCustomerSelect={handleSelectCustomer}
                  invoice={invoice}
                  mode={editing ? "edit" : "view"}
                  onEdit={saveCustomerChanges}
                  onEditEnter={() => setFieldFocused(true)}
                  onEditFail={() => {
                    if (!fieldError) {
                      setFieldError(true);
                    }
                  }}
                />
              </Grid>
              <Grid item container md={6}>
                <Grid item container alignItems="center" marginBottom={-0.5}>
                  <Typography
                    variant="h6"
                    align="left"
                    className="invoice-section-heading"
                  >
                    Invoice Details
                  </Typography>
                  <FiberManualRecordIcon
                    fontSize="inherit"
                    htmlColor={getStatusColors(invoice?.invoiceStatus.name)}
                    style={{ margin: "0 7 0 5", alignSelf: "center" }}
                  />
                  {invoice?.invoiceStatus.name}
                </Grid>
                <InvoiceDetails
                  invoice={invoice}
                  processors={processors}
                  mode={editing ? "edit" : "view"}
                  customFieldGroups={customFieldGroups}
                  chosenCustomFields={chosenCustomFields}
                  onEdit={saveInvoiceChanges}
                  onEditEnter={() => setFieldFocused(true)}
                  onEditFail={() => setFieldError(true)}
                />
              </Grid>
              <Grid item container xs={12}>
                <Grid item marginTop={2}>
                  <Typography
                    variant="h6"
                    align="left"
                    className="invoice-section-heading"
                  >
                    Line Item Details
                  </Typography>
                </Grid>
                {!unitsLoading && (
                  <LineItemDetails
                    invoice={invoice}
                    remittanceAddress={remittanceAddress}
                    unitsOfMeasure={unitsOfMeasure}
                    mode={editing ? "edit" : "view"}
                    onEdit={saveLineItemChanges}
                    onSubmit={handleSubmit}
                    onDelete={handleLineItemDelete}
                    disableSubmit={!formIsDirty || fieldFocused || fieldError}
                  />
                )}
              </Grid>
            </Grid>
          )}
          <PreventNavigationDialog
            when={!submitting && formIsDirty}
            navigate={history.push}
            shouldBlockNavigation={(nextLocation) =>
              !submitting && formIsDirty && nextLocation.pathname !== "/error"
            }
          />
        </>
      }
      alertDialogOpen={isAlertOpen}
      alertDialogProps={alertDialogProps}
    />
  );
};

export default InvoicesEditContainer;
