import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Divider,
  Typography,
  Button,
  Collapse,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ContactContainer from "../../../../Contact/ContactContainer";
import { CountriesContext } from "../../../../../contexts/CountriesContext";
import CustomerPaymentMethod from "./CustomerPaymentMethod";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../../../constants/routes";
import { SUBSCRIPTION_STATUSES } from "../../../../../constants/global";
import { EditIcon } from "../../../../../assets/icons/Icons";
import { useFormContext } from "react-hook-form";

const CustomerDetails = ({
  mode,
  subscription,
  customer,
  openCustomerDrawer,
  billingAddress,
  shippingAddress,
  loading,
}) => {
  const methods = useFormContext();
  const countries = useContext(CountriesContext);
  const history = useHistory();
  const [displayBilling, setDisplayBilling] = useState(mode === "create");
  const [displayShipping, setDisplayShipping] = useState(false);

  const formatAddress = (address) => {
    if (address) {
      address.address_line_1 = address.addressLine1;
      address.address_line_2 = address.addressLine2;
      address.postal_code = address.postalCode;
      address.first_name = address.firstName;
      address.last_name = address.lastName;
      return address;
    }
  };

  const customerPaymentMethod =
    subscription?.paymentMethod?.customerPaymentMethod ||
    customer?.paymentMethods?.find((pm) => pm.isDefault);

  return (
    <Grid container marginTop={2}>
      <Grid item marginY={2}>
        <Typography variant="h3">
          <strong>Customer Details</strong>
        </Typography>
      </Grid>
      <Grid item container className="subscription-details">
        <Grid item xs={12}>
          <Typography variant="body1">Customer</Typography>
        </Grid>
        <Grid item xs={12} marginBottom={1.5}>
          <Button
            variant="text"
            id="subscription-customer-name-button"
            onClick={() =>
              history.push(
                `${merchantRoutes.customer.base}/${
                  subscription?.customer.customerId || customer.customerId
                }`,
              )
            }
          >
            <Typography variant="h4" id="subscription-customer-name">
              <strong>{customer?.name || subscription?.customer?.name}</strong>
            </Typography>
          </Button>
          {!loading ? (
            (mode === "create" ||
              (mode === "edit" &&
                subscription?.status.name === SUBSCRIPTION_STATUSES.DRAFT)) && (
              <IconButton
                id="subscription-customer-edit-button"
                onClick={() => openCustomerDrawer(true)}
              >
                <EditIcon />
              </IconButton>
            )
          ) : (
            <CircularProgress className="loading-icon" size={15} />
          )}
        </Grid>
        {mode !== "view" ? (
          <>
            <Button
              variant="text"
              color="secondary"
              className="subscription-address-accordion-button"
              disableRipple
              onClick={() => setDisplayBilling(!displayBilling)}
            >
              <strong>
                Display Billing Details {displayBilling ? "-" : "+"}
              </strong>
            </Button>
            <Collapse in={displayBilling}>
              <ContactContainer
                v2
                type="billing"
                countries={countries}
                defaultValues={formatAddress(billingAddress)}
                required={["country", "state", "email"]}
                hidden={{
                  company: true,
                  phone: true,
                  fax: true,
                }}
                viewOnly={
                  mode === "edit" &&
                  subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
                }
              />
            </Collapse>
            <Button
              variant="text"
              color="secondary"
              className="subscription-address-accordion-button"
              disableRipple
              onClick={() => setDisplayShipping(!displayShipping)}
            >
              <strong>
                Display Shipping Details {displayShipping ? "-" : "+"}
              </strong>
            </Button>
            <Collapse in={displayShipping}>
              <ContactContainer
                v2
                type="shipping"
                countries={countries}
                defaultValues={formatAddress(shippingAddress)}
                required={
                  subscription?.lineItems?.find(
                    (lineItem) => lineItem.freightAmount,
                  ) ||
                  methods
                    .getValues("lineItems")
                    ?.find((lineItem) => lineItem.advancedFieldsEnabled)
                    ? ["country", "state", "address1"]
                    : null
                }
                hidden={{
                  company: true,
                  phone: true,
                  fax: true,
                }}
                viewOnly={
                  mode === "edit" &&
                  subscription?.status.name !== SUBSCRIPTION_STATUSES.DRAFT
                }
              />
            </Collapse>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">Email</Typography>
            </Grid>
            <Grid item xs={12} marginBottom={1.5}>
              <Typography variant="h5">
                <strong>
                  {subscription?.addresses?.find((a) => a.isBilling)?.email}
                </strong>
              </Typography>
            </Grid>
            <Divider />
            {customerPaymentMethod &&
              subscription?.paymentMethod?.isAutoCharged && (
                <>
                  <Grid item xs={12} paddingY={2}>
                    <CustomerPaymentMethod
                      paymentMethod={customerPaymentMethod}
                    />
                  </Grid>
                  <Divider />
                </>
              )}
            <Grid item xs={12} marginTop={2}>
              <Typography variant="body1">Billing Method</Typography>
            </Grid>
            <Grid item xs={12} marginBottom={1.5}>
              <Typography variant="h5">
                <strong>
                  {subscription?.paymentMethod.isAutoCharged
                    ? "Automatically charge"
                    : "Send customer an invoice to pay manually"}
                </strong>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

CustomerDetails.propTypes = {
  mode: PropTypes.oneOf(["view", "edit", "create"]),
  subscription: PropTypes.object,
  customer: PropTypes.object,
  openCustomerDrawer: PropTypes.func,
  billingAddress: PropTypes.object,
  shippingAddress: PropTypes.object,
  loading: PropTypes.bool,
};

export default CustomerDetails;
