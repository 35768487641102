export const merchantRoutes = {
  dashboard: "/merchant/dashboard",
  virtual_terminal: "/merchant/virtual-terminal",
  terminal: "/merchant/terminal",
  virtual_terminal_token: "/merchant/virtual-terminal/token/:id",
  reporting: {
    transactions: "/merchant/transaction",
    transaction_detail: "/merchant/transaction/detail",
    settlements: "/merchant/reporting/settlements",
    billing: "/merchant/reporting/billing",
  },
  invoices: {
    base: "/merchant/invoices",
    create: "/merchant/invoices/create",
    search: "/merchant/invoices/search",
  },
  shopping: {
    carts: "/merchant/shopping/carts",
    products: "/merchant/shopping/products",
    documentation: "/merchant/shopping/documentation",
  },
  customer: {
    base: "/merchant/customer",
    create: "/merchant/customer/create",
    search: "/merchant/customer/search",
    card_sync: "/merchant/customer/card-sync",
  },
  recurring_billing: {
    plans: "/merchant/recurring-billing/plans",
    addons: "/merchant/recurring-billing/addons",
    discounts: "/merchant/recurring-billing/discounts",
    subscriptions: "/merchant/recurring-billing/subscriptions",
  },
  file_batch: {
    upload_history: "/merchant/file-batch/upload",
    documentation: "/merchant/file-batch/documentation",
  },
  manage: {
    terminal: "/merchant/manage/terminal",
    users: "/merchant/manage/users",
    users_create: "/merchant/manage/users/create",
    iqprotect: "/merchant/manage/iqprotect",

    custom_fields: {
      search: "/merchant/manage/custom-fields",
      create: "/merchant/manage/custom-fields/create",
      update: "/merchant/manage/custom-fields/update",
    },
    cardbans: "/merchant/manage/cardbans",
    settings: "/merchant/manage/settings",
  },
  account: {
    manage_account: "/merchant/account/manage-account",
    manage_api_keys: "/merchant/account/manage-api-keys",
    change_password: "/merchant/account/change-password",
    logout: "/merchant/account/logout",
  },
  developer_hub: {
    applications: "/merchant/developer-hub/applications",
    webhooks: "/merchant/developer-hub/webhooks",
  },
  help: {
    contact_us: "/merchant/help/contact_us",
    terms_of_service: "/help/helptos",
  },
};

export const partnerRoutes = {
  dashboard: "/partner/dashboard",
  partners: "/partner/partners",
  gateway: "/partner/gateway",
  gateway_create: "/partner/gateway/create",
  gateway_view: "/partner/gateway/view/:id",
  billing: {
    reports: "/partner/billing/reports",
    fee_schedule: "/partner/billing/fee_schedule",
    commissions: "/partner/billing/commissions",
  },
  sales: {
    calculator: "/partner/sales/calculator",
  },
  transactions: "/partner/transactions",
  manage: {
    settings: "/partner/manage/settings",
    users: "/partner/manage/users",
    rules: "/partner/manage/rules",
    users_create: "/partner/manage/users/create",
    edit: "/partner/manage/users/:id",
  },
  account: {
    manage_account: "/partner/account/manage-account",
    manage_api_keys: "/partner/account/manage-api-keys",
    change_password: "/partner/account/change-password",
    logout: "/partner/account/logout",
  },
  help: {
    developer_hub: "/partner/help/developer-hub",
    contact_us: "/partner/help/contact_us",
    current_tos: "/help/current_tos",
    terms_of_service: "/help/helptos",
  },
};

export const generalError = "/404";
export const root = "/";
export const noProcessors = "/no-processors";
