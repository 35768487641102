import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import axios from "axios";
import SearchResults from "./SearchResults";
import ContentComponent from "../../Content/Content";
//import Select from "@mui/material/Select";
//import MenuItem from "@mui/material/MenuItem";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { sassEndpoints } from "../../../constants/endpoints";
import SearchManager from "../../Search/SearchManager";
import { FormProvider, useForm } from "react-hook-form";
import {
  transactionSearchFilterFields,
  quickActionButtons,
} from "./transactionSearchFilterFields";
import * as mp from "../../../services/mixpanel/merchant/reportingTransactionsEvents";
import { useLocation } from "react-router";
import { useHistory } from "react-router";
import DownloadButton from "../../Buttons/DownloadButton";
import "./reportingTransactions.scss";
import { transformToPDF } from "../../../utils/pdfGenerator";
import { keyInfoLookup } from "./SearchResults";
import PropTypes from "prop-types";
import { stringFormat } from "../../../utils/stringHelpers";
import { merchantRoutes } from "../../../constants/routes";
import { GatewayContext } from "../../../contexts/GatewayContext";

export const getOutputFileName = () => {
  let date = new Date();
  let dateFormatted = date.toLocaleDateString("en-us", {
    year: "numeric",
    day: "numeric",
    month: "short",
  });
  let fileName = "transactions-{0}.csv";
  dateFormatted = dateFormatted.replaceAll(",", "").replaceAll(" ", "-");
  fileName = fileName.replace("{0}", dateFormatted);
  return fileName;
};
const ReportingTransactionsContainer = ({ customerId }) => {
  const gatewayInfo = useContext(GatewayContext);
  const { ...methods } = useForm();
  const transactionsRef = useRef();
  const fieldBoldness = "600";
  const { userSettings } = useContext(UserSettingsContext);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [transactionSearchResults, setTransactionSearchResults] =
    useState(null);
  // eslint-disable-next-line no-unused-vars
  const [sortField, setSortField] = useState("created_date");
  const [sortDirection, setSortDirection] = useState("desc");
  const [forceUpdateResults, setForceUpdateResults] = useState();
  // eslint-disable-next-line no-unused-vars
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [promptDialogOpen, setPromptDialogOpen] = useState(false);
  const [promptDialogProps, setPromptDialogProps] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [searchPayload, setSearchPayload] = useState(null);
  const [pdfResults, setPDFResults] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [entitySearchFilterFields, setEntitySearchFilterFields] =
    useState(null);
  //const [downloadFormatId, setDownloadFormatId] = useState(null);
  const [resetPage, setResetPage] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  //CreatedDateTime|Status|CustomerName|MaskedAccount|MaskedCard|Amount
  useEffect(() => {
    if (searchClicked === true) {
      setResetPage(true);
    }
  }, [searchClicked]);
  const keyEnums = {
    masked_account: "MaskedAccount",
    amount: "Amount",
    created_date: "CreatedDateTime",
    status: "Status",
    type: "Type",
  };

  let mixpanelPayload = {
    page: "reporting transactions list",
  };
  const handleChangeAlertDialogProps = (props, alertOpen) => {
    if (alertOpen === false) {
      setAlertDialogOpen(false);
    } else {
      setAlertDialogProps(props);
      setAlertDialogOpen(true);
    }
  };
  const handleChangePromptDialogProps = (props, promptOpen) => {
    if (promptOpen === false) {
      setPromptDialogOpen(false);
    } else {
      setPromptDialogProps(props);
      setPromptDialogOpen(true);
    }
  };
  const handleResultsPerPageOnChange = (event) => {
    setResultOffset(0);
    setResultsPerPage(Number.parseInt(event.target.value));
  };
  const handleSearchButtonOnClick = (payload) => {
    setResultOffset(0);
    setSearchPayload(payload);
    setForceUpdateResults(Date.now());
    setSearchClicked(true);
  };

  useEffect(() => {
    let arrayCopy = [];
    transactionSearchFilterFields().forEach(function (item) {
      arrayCopy.push({ ...item });
    });
    if (location?.state?.settlement_batch_id) {
      setEntitySearchFilterFields(defaultToSettlements(arrayCopy));
      history.replace({ state: {} });
    } else if (location?.state?.invoiceId) {
      setEntitySearchFilterFields(defaultToInvoices(arrayCopy));
      history.replace({ state: {} });
    } else if (location?.state?.subscriptionId) {
      setEntitySearchFilterFields(defaultToSubscriptionId(arrayCopy));
      history.replace({ state: {} });
    } else {
      setEntitySearchFilterFields(arrayCopy);
    }
  }, []);

  const loadTransactionSearchResults = async () => {
    if (!searchPayload) return false;
    if (customerId) {
      delete searchPayload.createdDateTime;
      searchPayload.customerId = {
        operator: "equal",
        value: customerId,
      };
      searchPayload.limit = resultsPerPage;
      searchPayload.offset = resultOffset;
    } else {
      searchPayload.limit = resultsPerPage;
      searchPayload.offset = resultOffset;
    }

    setShowBackdrop(true);
    let payload = structuredClone(searchPayload);
    payload.sortColumn = keyEnums[sortField];
    payload.sortDirection = sortDirection.toUpperCase();
    Object.keys(payload).forEach((key) => {
      if (
        typeof payload[key] === "object" &&
        ("startDate" in payload[key] || "endDate" in payload[key])
      ) {
        payload[key].operator = "Between";
      }
    });

    await axios
      .post(
        stringFormat(sassEndpoints.transactions.search, [
          userSettings.gatewayId,
        ]),
        payload,
      )
      .then((response) => {
        if (response?.data?.data?.results?.length > 0) {
          setTransactionSearchResults(response.data.data.results);
          if (customerId && response.data.data.rowCount > 50) {
            setResultsTotal(50);
          } else {
            setResultsTotal(response.data.data.rowCount);
          }
        } else {
          setTransactionSearchResults(null);
          setResultsTotal(0);
        }
      })
      .catch((error) => {
        showAlertDialogError(error.response.data.statusDetails);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const loadTransactionSearchPDFResults = async () => {
    let url = stringFormat(sassEndpoints.transactions.search, [
      userSettings.gatewayId,
    ]);
    if (!searchPayload) return false;
    if (customerId) {
      delete searchPayload.createdDateTime;
      searchPayload.customerId = {
        operator: "equal",
        value: customerId,
      };
    }
    searchPayload.limit = 100;
    searchPayload.offset = 0;

    setShowBackdrop(true);
    let payload = structuredClone(searchPayload);
    payload.sortColumn = keyEnums[sortField];
    payload.sortDirection = sortDirection.toUpperCase();
    Object.keys(payload).forEach((key) => {
      if (
        typeof payload[key] === "object" &&
        ("startDate" in payload[key] || "endDate" in payload[key])
      ) {
        payload[key].operator = "Between";
      }
    });
    await axios
      .post(url, payload)
      .then(function (response) {
        setPDFResults(response.data.data.results);
        transformToPDF(transactionsRef, "Transactions.pdf", 1350);
      })
      .catch(function (error) {
        showAlertDialogError(error.response.data.msg);
        console.log("Failed to get transaction details", error);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const handleClosePromptDialog = () => {
    handleChangePromptDialogProps({}, false);
  };
  const handleDownloadTransactionsOnClick = () => {
    downloadTransactionSearchResult();
    /*handleChangePromptDialogProps(
      {
        alertTitle: "Select Format",
        contentElement: (
          <Select
            fullWidth
            displayEmpty
            {...methods.register("download_format", {
              shouldUnregister: true,
            })}
            sx={{ fontSize: 12, textAlign: "left" }}
            size="small"
            onChange={(e) => setDownloadFormatId(e.target.value)}
            defaultValue={downloadFormatId || ""}
          >
            <MenuItem value="">Standard</MenuItem>
            {customOutput &&
              customOutput
                .filter((option) => option.type === "transaction")
                .map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
          </Select>
        ),
        actionButtons: [
          {
            text: "Create",
            color: "secondary",
            onclick: downloadTransactionSearchResult,
          },
        ],
        closeButtonText: "Cancel",
        closeButtonColor: "neutrals",
        onCloseButtonClick: handleClosePromptDialog,
      },
      true
    );*/
  };
  const downloadTransactionSearchResult = async () => {
    let url = stringFormat(sassEndpoints.transactions.searchCSV, [
      userSettings.gatewayId,
    ]);
    let payload = structuredClone(searchPayload);
    payload.sortColumn = keyEnums[sortField];
    payload.sortDirection = sortDirection.toUpperCase();
    Object.keys(payload).forEach((key) => {
      if (
        typeof payload[key] === "object" &&
        ("startDate" in payload[key] || "endDate" in payload[key])
      ) {
        payload[key].operator = "Between";
      }
    });

    setShowBackdrop(true);
    await axios
      .post(url, payload)
      .then(function (response) {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", getOutputFileName());
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(function (error) {
        console.log("Failed to download transactions output file", error);
      })
      .finally(function () {
        handleClosePromptDialog();
        setShowBackdrop(false);
      });
  };
  const refundTransaction = async (transactionId, amount) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.transactions.refund, [
      userSettings.gatewayId,
      transactionId,
    ]);
    Object.assign(mixpanelPayload, {
      transaction_id: transactionId,
      action_type: "refunded",
      amount: amount,
    });
    await axios
      .post(url, { amount: amount })
      .then(function () {
        setPromptDialogOpen(false);
        showSnackBar("Your request has been refunded! ");
        setForceUpdateResults(Date.now());
        mp.reportingTransactionsActionSuccess(mixpanelPayload);
      })
      .catch(function (error) {
        showAlertDialogError(error.response.data.msg);
        Object.assign(mixpanelPayload, {
          error: error.response.data.msg,
        });
        mp.reportingTransactionsActionFailed(mixpanelPayload);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const captureTransaction = async (transactionId, amount) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.transactions.capture, [
      userSettings.gatewayId,
      transactionId,
    ]);
    Object.assign(mixpanelPayload, {
      transaction_id: transactionId,
      action_type: "captured",
      amount: amount,
    });
    await axios
      .put(url, { amount: amount })
      .then(function () {
        setPromptDialogOpen(false);
        showSnackBar("Your request has been captured! ");
        setForceUpdateResults(Date.now());
        mp.reportingTransactionsActionSuccess(mixpanelPayload);
      })
      .catch(function (error) {
        showAlertDialogError(error.response.data.msg);
        Object.assign(mixpanelPayload, {
          error: error.response.data.msg,
        });
        mp.reportingTransactionsActionFailed(mixpanelPayload);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const emailTransaction = async (transactionId, emailAddresses) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.transactions.emailReceipt, [
      userSettings.gatewayId,
      transactionId,
    ]);
    let errorEmailAddress = [];
    let emailAdressesArray = emailAddresses.split(",");
    for (let index in emailAdressesArray) {
      Object.assign(mixpanelPayload, {
        transaction_id: transactionId,
        action_type: "emailed",
        email: emailAdressesArray[index].trim(),
      });
      await axios
        .post(url, {
          recipient: {
            to: [emailAdressesArray[index].trim()],
            name: "",
          },
          from: gatewayInfo.receiptEmail || "",
        })
        .catch(function () {
          errorEmailAddress.push(emailAdressesArray[index].trim());
        })
        .finally(function () {
          setShowBackdrop(false);
        });
    }
    if (errorEmailAddress.length !== 0) {
      let errorMessage = ["Email's could not be sent to the following:"];
      for (let index in errorEmailAddress) {
        errorMessage.push(errorEmailAddress[index]);
      }
      showAlertDialogError(errorMessage);
      mp.reportingTransactionsActionFailed(mixpanelPayload);
    } else {
      setPromptDialogOpen(false);
      showSnackBar("Your email(s) has been sent!");
      mp.reportingTransactionsActionSuccess(mixpanelPayload);
    }
  };
  const voidTransaction = async (transactionId) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.transactions.void, [
      userSettings.gatewayId,
      transactionId,
    ]);
    Object.assign(mixpanelPayload, {
      transaction_id: transactionId,
      action_type: "voided",
    });
    await axios
      .put(url, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then(function () {
        setPromptDialogOpen(false);
        showSnackBar("Request has been voided!");
        setForceUpdateResults(Date.now());
        mp.reportingTransactionsActionSuccess(mixpanelPayload);
      })
      .catch(function (error) {
        showAlertDialogError(error.response.data.msg);
        Object.assign(mixpanelPayload, {
          error: error.response.data.msg,
        });
        mp.reportingTransactionsActionFailed(mixpanelPayload);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const incrementAuthorization = async (transactionId, amount) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.transactions.incrementAuth, [
      userSettings.gatewayId,
      transactionId,
    ]);
    let mixpanelPayload = {
      transaction_id: transactionId,
      action_type: "incremented",
      amount: amount,
    };

    try {
      await axios.put(url, { amount: amount });

      setPromptDialogOpen(false);
      showSnackBar("Auth Amount Increased Successfully!");
      setForceUpdateResults(Date.now());
      mp.reportingTransactionsActionSuccess(mixpanelPayload);
    } catch (error) {
      showAlertDialogError(error.response.data.msg);
      Object.assign(mixpanelPayload, {
        error: error.response.data.msg,
      });
      mp.reportingTransactionsActionFailed(mixpanelPayload);
    } finally {
      setShowBackdrop(false);
    }
  };

  const vaultTransaction = async (transactionId) => {
    setShowBackdrop(true);
    const url = stringFormat(sassEndpoints.transactions.vaultCustomer, [
      userSettings.gatewayId,
      transactionId,
    ]);
    Object.assign(mixpanelPayload, {
      transaction_id: transactionId,
      action_type: "vaulted",
    });
    await axios
      .post(url, {
        customerName: "",
        description:
          "Customer vaulted from transaction with TransactionId: " +
          transactionId,
      })
      .then(function (response) {
        setPromptDialogOpen(false);
        showSnackBar(
          <>
            Vault Complete.
            <br />
            To access or modify customer information, please click{" "}
            <a
              style={{ color: "#fff", fontWeight: "bold" }}
              href={`${merchantRoutes.customer.base}/${response.data.data.customerId}`}
            >
              open vaulted customer
            </a>
            .
          </>,
        );
        setForceUpdateResults(Date.now());
        mp.reportingTransactionsActionSuccess(mixpanelPayload);
      })
      .catch(function (error) {
        var errorMsg = error.response.data.statusDetails
          ? error.response.data.statusDetails.join(", ")
          : error;
        showAlertDialogError(errorMsg);
        Object.assign(mixpanelPayload, {
          error: errorMsg,
        });
        mp.reportingTransactionsActionFailed(mixpanelPayload);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };
  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };
  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      closeButtonColor: "secondary",
      alertMessages: Array.isArray(alertMessage)
        ? alertMessage
        : [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };
  useLayoutEffect(() => {
    resetSorting();
  }, []);
  const handleSortOnClick = (key) => {
    resetSorting();
    if (key !== sortField) {
      setSortDirection("desc");
      keyInfoLookup.map((keyInfo) => {
        return (keyInfo.sortType = keyInfo.key === key ? "desc" : null);
      });
    } else {
      let newSortDirection;
      if (sortDirection === "asc") {
        newSortDirection = "desc";
      } else {
        newSortDirection = "asc";
      }
      setSortDirection(newSortDirection);
      keyInfoLookup.map((keyInfo) => {
        return (keyInfo.sortType =
          keyInfo.key === key ? newSortDirection : null);
      });
    }
    setSortField(key);
  };

  const resetSorting = () => {
    keyInfoLookup.map((key) => {
      return (key.sortType = null);
    });
  };
  const handlePageChange = (pageNumber) => {
    let pageIndex = pageNumber - 1;
    setResultOffset(pageIndex * resultsPerPage);
  };
  const csvPdfExport = (val) => {
    if (resultsTotal > 0) {
      val === "CSV"
        ? handleDownloadTransactionsOnClick()
        : loadTransactionSearchPDFResults();
    } else {
      showAlertDialogError("No available rows to download.");
    }
  };
  const handleSelection = (fileType) => {
    csvPdfExport(fileType);
  };
  const defaultToSettlements = (searchFields) => {
    const batchId = searchFields.find((field) => {
      return field.fieldName === "settlementBatchId";
    });
    batchId.selected = true;
    batchId.savedValues = {
      operator: "Equal",
      value: location.state.settlement_batch_id,
    };
    const dateRange = searchFields.find((field) => {
      return field.fieldName === "createdDateTime";
    });
    dateRange.selected = false;
    delete dateRange.savedValues;
    return searchFields;
  };

  const defaultToInvoices = (searchFields) => {
    const invoiceId = searchFields.find(
      (field) => field.fieldName === "invoiceId",
    );
    const dateRange = searchFields.find(
      (field) => field.fieldName === "createdDateTime",
    );

    invoiceId.selected = true;
    invoiceId.savedValues = {
      operator: "Equal",
      value: location.state.invoiceId,
    };

    dateRange.selected = false;
    delete dateRange.savedValues;

    return searchFields;
  };

  const defaultToSubscriptionId = (searchFields) => {
    const subscriptionId = searchFields.find(
      (field) => field.fieldName === "subscriptionId",
    );
    const dateRange = searchFields.find(
      (field) => field.fieldName === "createdDateTime",
    );

    subscriptionId.selected = true;
    subscriptionId.savedValues = {
      operator: "Equal",
      value: location.state.subscriptionId,
    };

    dateRange.selected = false;
    delete dateRange.savedValues;

    return searchFields;
  };

  useEffect(() => {
    if (userSettings !== null) {
      loadTransactionSearchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userSettings,
    resultOffset,
    resultsPerPage,
    sortField,
    sortDirection,
    forceUpdateResults,
  ]);
  if (entitySearchFilterFields === null) return <></>;
  else
    return (
      <FormProvider {...methods}>
        <ContentComponent
          spinnerOpen={showBackdrop}
          useFixedWidth={false}
          title="Transactions List"
          headerContent={
            <SearchManager
              sectionName={"Transaction"}
              searchButtonOnClick={handleSearchButtonOnClick}
              showSnackBar={showSnackBar}
              showAlertDialogError={showAlertDialogError}
              entitySearchFilterFields={entitySearchFilterFields}
              suppressAdvancedSearch={!location?.state}
              quickActionButtons={quickActionButtons}
              setSearchClicked={setSearchClicked}
            />
          }
          bodyContent={
            <div className="transactions-wrapper">
              {/* <IncreaseAuthDialog
                open={showIncreaseAuthDialog}
                onClose={() => setShowIncreaseAuthDialog(false)}
                transactionData={{
                  amountAuthorized:
                    transactionData.remit.amountAuthorized,
                  tax: transactionData.remit.taxAmount,
                  paymentAdjustment:
                    transactionData.remit.paymentAdjustmentValue,
                  surcharge: transactionData.remit.surcharge,
                }}
                onProcess={handleProcessIncreaseAuth}
              /> */}
              <DownloadButton
                handleSelection={handleSelection}
                types={["CSV", "PDF"]}
                disabledItems={resultsTotal > 100 ? ["PDF"] : []}
              />

              <div>
                <>
                  {" "}
                  <SearchResults
                    fieldBoldness={fieldBoldness}
                    transactionSearchResults={transactionSearchResults}
                    handleSortOnClick={handleSortOnClick}
                    resultOffset={resultOffset}
                    resultsPerPage={resultsPerPage}
                    resultsTotal={resultsTotal}
                    userSettings={userSettings}
                    refundTransaction={refundTransaction}
                    captureTransaction={captureTransaction}
                    vaultTransaction={vaultTransaction}
                    emailTransaction={emailTransaction}
                    voidTransaction={voidTransaction}
                    incrementAuth={incrementAuthorization}
                    handleChangeAlertDialogProps={handleChangeAlertDialogProps}
                    handleChangePromptDialogProps={
                      handleChangePromptDialogProps
                    }
                    showAlertDialogError={showAlertDialogError}
                    isPDF={false}
                    handleChange={handlePageChange}
                    handleResultsPerPageOnChange={handleResultsPerPageOnChange}
                    resetPage={resetPage}
                    setResetPage={setResetPage}
                    setSearchClicked={setSearchClicked}
                  />
                </>
                <div className="transaction-pdf-table" ref={transactionsRef}>
                  <SearchResults
                    fieldBoldness={fieldBoldness}
                    transactionSearchResults={pdfResults}
                    handleSortOnClick={handleSortOnClick}
                    resultOffset={resultOffset}
                    resultsPerPage={resultsPerPage}
                    resultsTotal={resultsTotal}
                    userSettings={userSettings}
                    refundTransaction={refundTransaction}
                    captureTransaction={captureTransaction}
                    vaultTransaction={vaultTransaction}
                    incrementAuthorization={incrementAuthorization}
                    emailTransaction={emailTransaction}
                    voidTransaction={voidTransaction}
                    handleChangeAlertDialogProps={handleChangeAlertDialogProps}
                    handleChangePromptDialogProps={
                      handleChangePromptDialogProps
                    }
                    showAlertDialogError={showAlertDialogError}
                    isPDF={true}
                  />
                </div>
              </div>
            </div>
          }
          alertDialogOpen={alertDialogOpen}
          alertDialogProps={alertDialogProps}
          promptDialogOpen={promptDialogOpen}
          promptDialogProps={promptDialogProps}
          snackbarOpen={snackbarOpen}
          snackbarProps={snackbarProps}
        />
      </FormProvider>
    );
};

ReportingTransactionsContainer.propTypes = {
  customerId: PropTypes.string,
};

export default ReportingTransactionsContainer;
