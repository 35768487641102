import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Grid, MenuItem } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useAlertDialog } from "../../../../hooks";
import Dinero from "dinero.js";
import Select from "../../../ReactHookForm/Select";
import AlertDialog from "../../../Dialog/AlertDialog";
import { getGatewayConfigFieldVal } from "../../../../utils/objectHelpers";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import LineItemTotals from "./LineItemTotals";

export const getDefaultLineItem = (gatewayConfig, unitsOfMeasure) => {
  const localTaxPercent = Math.round(
    parseFloat(
      getGatewayConfigFieldVal(
        gatewayConfig,
        "default_values",
        "Line Items",
        "local_tax_rate",
      ),
    ) * 1000,
  );
  const nationalTaxPercent = Math.round(
    parseFloat(
      getGatewayConfigFieldVal(
        gatewayConfig,
        "default_values",
        "Line Items",
        "national_tax_rate",
      ),
    ) * 1000,
  );
  const isTaxable =
    getGatewayConfigFieldVal(
      gatewayConfig,
      "default_values",
      "Line Items",
      "taxable",
    )?.toLowerCase() === "true";
  const unitOfMeasure = gatewayConfig?.categories
    ?.find((c) => c.code === "default_values")
    ?.groups?.find((g) => g.id === 3)
    ?.fields?.find((f) => f.code === "unit_of_measure");
  const unitOfMeasureId = unitsOfMeasure.find(
    (uom) =>
      uom.name ===
      unitOfMeasure.options.find((o) => o.value === unitOfMeasure.value)?.label,
  )?.unitOfMeasureId;

  return {
    name: "",
    description: "",
    quantity: 1,
    unitPrice: 0,
    discount: 0,
    unitOfMeasureId,
    isTaxable,
    localTaxPercent,
    nationalTaxPercent,
    advancedFieldsEnabled: false,
    shippedAmount: null,
    freightAmount: null,
  };
};

const defaultFees = {
  subtotal: 0,
  discounts: 0,
  tax: 0,
  total: 0,
  remainingBalance: 0,
};

const InvoiceStepThree = ({
  invoice,
  onPageChange,
  onSubmit,
  unitsOfMeasure,
  mode,
  onEdit,
  onDelete,
  disableSubmit,
}) => {
  const { gatewayConfigSettings } = useContext(GatewayConfigContext);
  const defaultLineItem = getDefaultLineItem(
    gatewayConfigSettings,
    unitsOfMeasure,
  );
  const { isAlertOpen, setIsAlertOpen, alertMessages } = useAlertDialog();
  const [fees, setFees] = useState({});
  const [alertDialogProps, setAlertDialogProps] = useState({
    alertTitle: "Error",
    alertLevel: "error",
    alertMessages,
    onCloseButtonClick: () => setIsAlertOpen(false),
  });

  console.log(invoice);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      lineItems: invoice.lineItems?.map((item) => ({
        ...item,
        unitPrice: Math.round(item.unitPrice * 100),
        discount: Math.round(item.discount * 100),
        freightAmount: item.freightAmount
          ? Math.round(item.freightAmount * 100)
          : null,
        shippedAmount: item.shippedAmount || null,
        localTaxPercent: item.isTaxable
          ? Math.round(item.localTaxPercent * 1000)
          : defaultLineItem.localTaxPercent,
        nationalTaxPercent: item.isTaxable
          ? Math.round(item.nationalTaxPercent * 1000)
          : defaultLineItem.nationalTaxPercent,
        advancedFieldsEnabled:
          (item.shippedAmount || null) !== null ||
          (item.freightAmount || null) !== null,
        unitOfMeasureId:
          item.unitOfMeasureId || defaultLineItem.unitOfMeasureId,
      })) || [defaultLineItem],
    },
  });

  const formatInvoice = ({ lineItems, invoiceStatusId }) => {
    return {
      invoiceStatusId,
      invoiceTypeId: 1, // Hard coding invoice type id to OneTime for MVP
      lineItems: lineItems.map((item) => ({
        ...item,
        unitPrice: Dinero({ amount: item.unitPrice }).toUnit(),
        discount: Dinero({ amount: item.discount }).toUnit(),
        shippedAmount: item.advancedFieldsEnabled ? item.shippedAmount : null,
        freightAmount: item.advancedFieldsEnabled
          ? Dinero({
              amount: item.freightAmount || 0,
            }).toUnit()
          : null,
        unitOfMeasureId: item.advancedFieldsEnabled
          ? item.unitOfMeasureId
          : null,
        localTaxPercent: item.isTaxable
          ? Dinero({
              amount: item.localTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
        nationalTaxPercent: item.isTaxable
          ? Dinero({
              amount: item.nationalTaxPercent || 0,
              precision: 3,
            }).toUnit()
          : 0,
      })),
      remainingBalance: parseFloat(fees.total),
      subtotal: parseFloat(fees.subtotal),
      total: parseFloat(fees.total),
      tax: parseFloat(fees.tax),
      discounts: parseFloat(fees.discounts),
    };
  };

  const handlePageChange = (invoice) => {
    onPageChange(formatInvoice(invoice));
  };

  const handleSubmit = (invoice) => {
    setIsAlertOpen(false);
    methods.setValue("invoiceStatusId", "");
    onSubmit(formatInvoice(invoice));
  };

  const handleMarkAsPaid = (invoice) => {
    invoice.invoiceStatusId = 4;
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: [
        "Once this invoice is marked as paid, this invoice will be created and this action cannot be updated or undone.",
      ],
      actionButtons: [
        {
          text: "Yes, Mark Paid!",
          color: "secondary",
          onclick: () => handleSubmit(invoice),
        },
      ],
      closeButtonText: "Cancel",
      closeButtonColor: "secondary",
      closeButtonVariant: "outlined",
      onCloseButtonClick: () => setIsAlertOpen(false),
    });
    setIsAlertOpen(true);
  };

  const handleFeesChange = (fees) => {
    setFees(fees);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => e.preventDefault()}
        onBlur={
          onEdit && Object.keys(methods.formState.errors).length === 0
            ? methods.handleSubmit((values) => onEdit(formatInvoice(values)))
            : null
        }
      >
        <Grid container>
          <Grid container>
            <Grid item container className="invoice-section" padding={4}>
              <LineItemTotals
                object={invoice}
                defaultLineItem={defaultLineItem}
                defaultFees={
                  invoice?.remainingBalance !== undefined
                    ? {
                        subtotal: invoice.subtotal,
                        discounts: invoice.discounts,
                        tax: invoice.tax,
                        shipping: { amount: invoice.shipping },
                        total:
                          invoice.total !== undefined
                            ? invoice.total
                            : invoice.remainingBalance,
                        remainingBalance: invoice.remainingBalance,
                      }
                    : defaultFees
                }
                unitsOfMeasure={unitsOfMeasure}
                mode={mode}
                onDelete={onDelete}
                onFeesChange={handleFeesChange}
                formatValues={formatInvoice}
              />
            </Grid>
          </Grid>
          {mode !== "view" && (
            <Grid
              item
              container
              xs={12}
              spacing={1}
              className="next-page-button"
            >
              {mode === "create" && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handlePageChange(methods.getValues())}
                  >
                    Previous
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={methods.handleSubmit(handleMarkAsPaid)}
                  data-cy="paid-invoice"
                  disabled={!methods.formState.isValid || fees.total === 0}
                >
                  Mark As Paid
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <Select
                  control={methods.control}
                  name="invoiceStatusId"
                  label={mode === "create" ? "Create" : "Update"}
                  fullWidth
                  className="invoice-create-menu"
                  disabled={
                    !methods.formState.isValid ||
                    fees.total === 0 ||
                    disableSubmit
                  }
                  onChange={methods.handleSubmit(handleSubmit)}
                >
                  <MenuItem value={1}>{`${
                    mode === "create" ? "Create" : "Update"
                  } and Save`}</MenuItem>
                  {(invoice.invoiceNotifications.length > 0 ||
                    mode === "edit") && (
                    <MenuItem value={3}>{`${
                      mode === "create" ? "Create" : "Update"
                    } and Send`}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
      <AlertDialog {...alertDialogProps} alertOpen={isAlertOpen} />
    </FormProvider>
  );
};

InvoiceStepThree.propTypes = {
  invoice: PropTypes.object,
  onPageChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  unitsOfMeasure: PropTypes.arrayOf(PropTypes.object),
  mode: PropTypes.oneOf(["create", "edit", "view"]),
  disableSubmit: PropTypes.bool,
};

InvoiceStepThree.defaultProps = {
  mode: "create",
};

export default InvoiceStepThree;
