import React from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";

const Filters = ({ filters, values, setValues, onSearch, setOpenDrawer }) => {
  const theme = useTheme();

  const getFilterComponent = (filter, index) => {
    switch (filter.type) {
      case "text":
        return (
          <TextField
            key={index}
            className="search-list-filter-field"
            value={values[filter.name]}
            placeholder={filter.label}
            onChange={(e) =>
              setValues({ ...values, [filter.name]: e.target.value })
            }
            InputLabelProps={{
              shrink: false,
            }}
          />
        );

      case "select":
        return (
          <Select
            key={index}
            className="search-list-filter-field"
            name={`filter.${filter.name}`}
            value={values[filter.name] || ""}
            onChange={(e) =>
              setValues({ ...values, [filter.name]: e.target.value })
            }
            displayEmpty
            renderValue={(selected) => {
              if (selected)
                return (
                  filter.options.find(
                    (option) => option.value === values[filter.name],
                  )?.label || "None"
                );
              else return filter.label;
            }}
            sx={{
              ".MuiSelect-select": { opacity: values[filter.name] ? 1 : 0.5 },
            }}
          >
            <MenuItem value="">None</MenuItem>
            {filter.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );

      case "date":
        return (
          <TextField
            key={index}
            type="date"
            label={filter.label}
            name={`filter.${filter.name}`}
            value={values[filter.name]}
            onChange={(e) =>
              setValues({
                ...values,
                [filter.name]: new Date(e.target.value)
                  .toISOString()
                  .split("T")[0],
              })
            }
            inputProps={{
              sx: {
                colorScheme: theme.palette.mode,
              },
            }}
            InputLabelProps={{
              shrink: false,
            }}
            fullWidth
          />
        );
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid>
        <RegisteredTextField
          v2
          className="search-list-input"
          placeholder="Search"
          name="searchInput"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <Button
                variant="contained"
                className="search-list-input-button"
                onClick={onSearch}
              >
                <SearchIcon htmlColor="#000" fontSize="inherit" />
              </Button>
            ),
          }}
        />
        {filters.map(
          (filter, index) =>
            filter.toolbar && getFilterComponent(filter, index),
        )}
      </Grid>
      <Grid className="search-list-filter-buttons">
        <Button
          variant="text"
          id="all-filters-button"
          onClick={() => setOpenDrawer(true)}
        >
          All Filters
        </Button>
        <Button variant="contained" color="secondary" onClick={onSearch}>
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

Filters.propTypes = {
  onSearch: PropTypes.func,
  setOpenDrawer: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "date", "checkbox", "select"]).isRequired,
      toolbar: PropTypes.bool.isRequired,
      operator: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    }),
  ),
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default Filters;
